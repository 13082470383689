import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { SapOrderStatus } from './Types'

declare global {
	interface Date {
		AddDays(days: number): Date
		DiffDays(date: Date): number
	}
}

// eslint-disable-next-line no-extend-native
Date.prototype.AddDays = function (days: number): Date {
	const date = new Date(this.valueOf())
	date.setDate(date.getDate() + days)
	return date
}

// eslint-disable-next-line no-extend-native
Date.prototype.DiffDays = function (
	date: Date,
	lastDayInclude: Boolean = true,
): number {
	const dt = lastDayInclude
		? new Date(this.valueOf()).AddDays(1)
		: new Date(this.valueOf())

	const diff = Math.abs(dt.getTime() - date.getTime())
	const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
	return diffDays
}

export const mapSapOrderStatusResponse = (response: any): SapOrderStatus => ({
	...response,
	dateCreate: new Date(response.dateCreate),
})

export const RequestGet = async <T>(
	url: string,
	callback: (r: any) => any,
	accessToken?: string,
) => {
	const config: AxiosRequestConfig = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	}

	return axios
		.get<any, AxiosResponse<T>>(url, config)
		.then(response => callback(response))
		.catch(ex => {
			throw ex
		})
		.finally(() => {})
}

export const RequestGetBlob = async <T>(
	url: string,
	callback: (r: any) => any,
	accessToken?: string,
) => {
	const config: AxiosRequestConfig = {
		responseType: 'blob',
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	}

	return axios
		.get<any, AxiosResponse<T>>(url, config)
		.then(response => callback(response))
		.catch(ex => {
			throw ex
		})
		.finally(() => {})
}

export const RequestPatch = async <T>(
	url: string,
	callback: (r: any) => any,
	value: any,
	accessToken?: string,
) => {
	const config: AxiosRequestConfig = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	}

	return axios
		.patch<any, AxiosResponse<T>>(url, value, config)
		.then(response => callback(response))
		.catch(ex => {
			if (ex.response) {
				// client received an error response (5xx, 4xx)
				if (ex.response.status === 400) {
					throw new Error('Object not updated')
				}
			}

			throw ex
		})
		.finally(() => {})
}

export const RequestPost = async <T>(
	url: string,
	value: any,
	thenCallback?: (r: any) => any,
	catchCallback?: (r: any) => any,
	finallyCallback?: () => any,
	accessToken?: string,
) => {
	const config: AxiosRequestConfig = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	}

	return axios
		.post<any, AxiosResponse<T>>(url, value, config)
		.then(response => {
			return thenCallback ? thenCallback(response) : response.data
		})
		.catch(ex => {
			if (catchCallback) return catchCallback(ex)

			if (ex.response) {
				// client received an error response (5xx, 4xx)
				if (ex.response.status === 400) {
					throw new Error('Object not inserted')
				}
			}

			throw ex
		})
		.finally(() => {
			if (finallyCallback) return finallyCallback()
		})
}

export const DateFormat = (date: Date) => {
	// todo танцы с бубном из-за формата даты с бэка
	// формат даты в зависимости от часового пояса клиента, если не получится поставь moment js
	const dt = new Date(date)
	const userTimezoneOffset = dt.getTimezoneOffset() * 60000

	return new Date(dt.getTime() - userTimezoneOffset)
}
