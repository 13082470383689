import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const YetAnotherLink = css`
	cursor: pointer;
	font-size: 13px;
	color: #00a8ec;
	text-decoration: underline;
`

export const MenuItemLink = css`
	cursor: pointer;
	width: 100%;
	display: flex;
	justify-content: center;
	text-decoration: none;
	color: #fff;
	font-size: 14px;
	padding: 16px 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`

export const MenuItemLink2 = css`
	cursor: pointer;
	display: flex;
	justify-content: center;
	color: #fff;
	font-size: 14px;
	padding: 8px 12px;
	margin: 4px;
	text-decoration: none;
	border-radius: 5px;
	:hover {
		background: #cd0000;
	}
`

export const MenuItem = css`
	float: left;
	height: 50px;
	width: 90px;
	position: relative;
	:hover {
		background: url('/menu_hover.png') repeat-x;
		box-shadow: 0 0 1px #fff inset;
	}
	:focus {
		background: url('/menu_hover.png') repeat-x;
		box-shadow: 0 0 1px #fff inset;
	}
	:hover ul {
		display: block;
	}
	:focus ul {
		display: block;
	}
`

export const MenuSubItem = css`
	display: none;
	position: absolute;
	padding: 2px;
	left: 0;
	top: 49px;
	list-style: none;
	width: 200px;
	background: #fe1a00;
	border-radius: 0 0 5px 5px;
	box-shadow: 3px 10px 10px #777;
	z-index: 1;
`

export const Language = styled.div`
	display: inline-block;
	padding: 4px;
`
export const Hr = styled.div`
	width: 100%;
	height: 40px;
	background: url('/hr.png') no-repeat bottom center;
`
