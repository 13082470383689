import { DataGrid } from 'devextreme-react'
import {
	Button,
	Column,
	Editing,
	FormItem,
	Lookup,
	MasterDetail,
	Paging,
	Scrolling,
	SearchPanel,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import { useAuth } from 'oidc-react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Uri } from '../../AppSettings'
import {
	DateFormat,
	RequestGet,
	RequestGetBlob,
	RequestPatch,
	RequestPost,
} from '../../components/Data/Functions'
import {
	City,
	Client,
	Manager,
	Rent,
	RentState,
} from '../../components/Data/Types'
import { useDownloadFile } from '../../components/Data/useDownloadFile'
import { RentDetails } from '../../components/RentDetails'
import {
	gettingAllCitiesAction,
	gotAllCitiesAction,
} from '../../store/CitiesReducer'
import {
	gettingAllClientsAction,
	gotAllClientsAction,
} from '../../store/ClientsReducer'
import {
	gettingAllManagersAction,
	gotAllManagersAction,
} from '../../store/ManagersReducer'
import {
	gettingAllRentsAction,
	gotAllRentsAction,
} from '../../store/RentsReducer'
import {
	gettingAllRentStatesAction,
	gotAllRentStatesAction,
} from '../../store/RentStatesReducer'
import { AppState } from '../../store/Store'
import { Page } from '../Page'

export const RentsPage = () => {
	const authState = useAuth()
	const getUser = async () =>
		await authState.userManager.getUser().then(user => user)

	const dispatch = useDispatch()
	const rents = useSelector((state: AppState) => state.rents.all)
	const clients = useSelector((state: AppState) => state.clients.all)
	const managers = useSelector((state: AppState) => state.managers.all)
	const cities = useSelector((state: AppState) => state.cities.all)
	const rentStates = useSelector((state: AppState) => state.rentStates.all)

	const { ref, url, download, name } = useDownloadFile({
		apiDefinition: async (data: Rent) => {
			const user = await getUser()

			if (user) {
				const url = `${Uri.Rents}/${data.id}/contract`

				const blobData: Blob = await RequestGetBlob<Blob>(
					url,
					response => response.data,
					user.access_token,
				)

				return blobData
			}

			throw new Error('Blob problem')
		},
		preDownloading: () => {},
		postDownloading: () => {},
		onError: () => {
			//todo сделать ошибки красивее
			alert('Возникла проблема при генерации документа')
		},
		getFileName: (data: Rent) => {
			return `Договор аренды #${data.id}.docx`
		},
	})

	useEffect(() => {
		const getRentsData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllRentsAction())
				const data = await RequestGet<Rent[]>(
					Uri.Rents,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllRentsAction(data))
			}
		}

		const getRentStatesData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllRentStatesAction())
				const data = await RequestGet<RentState[]>(
					Uri.RentStates,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllRentStatesAction(data))
			}
		}

		const getClientsData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllClientsAction())
				const data = await RequestGet<Client[]>(
					Uri.Clients,
					response => {
						response.data.forEach((elem: Client) => {
							if (elem.dateBirth) {
								elem.dateBirth = DateFormat(elem.dateBirth)
							}
						})

						return response.data
					},
					user.access_token,
				)
				dispatch(gotAllClientsAction(data))
			}
		}
		const getCitiesData = async () => {
			dispatch(gettingAllCitiesAction())
			const data = await RequestGet<City[]>(
				Uri.Cities,
				response => response.data,
			)
			dispatch(gotAllCitiesAction(data))
		}

		const getManagersData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllManagersAction())
				const data = await RequestGet<Manager[]>(
					Uri.Managers,
					response => {
						response.data.forEach((elem: Manager) => {
							if (elem.dateBirth) {
								elem.dateBirth = DateFormat(elem.dateBirth)
							}
						})

						return response.data
					},
					user.access_token,
				)
				dispatch(gotAllManagersAction(data))
			}
		}

		getRentsData()
		getRentStatesData()
		getClientsData()
		getCitiesData()
		getManagersData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const rentsStore = new CustomStore({
		key: 'id',
		loadMode: 'raw',
		load: () => rents,
		insert: async value => {
			const user = await getUser()

			if (user) {
				return RequestPost<Rent>(
					Uri.Rents,
					value,
					undefined,
					undefined,
					undefined,
					user.access_token,
				)
			}
		},
		update: async (key, value) => {
			const user = await getUser()

			if (user) {
				return RequestPatch<Rent>(
					`${Uri.Rents}/${key}`,
					response => response.data,
					value,
					user.access_token,
				)
			}
		},
		remove: async key => {},
	})

	const onPrintContract = (e: any) => {
		const data: Rent = e.row.data
		download(data)
	}

	return (
		<Page title="Аренды">
			<a href={url} download={name} className="hidden" ref={ref} />
			<DataGrid
				dataSource={rentsStore}
				allowColumnReordering={true}
				allowColumnResizing={true}
				columnResizingMode="widget"
				hoverStateEnabled={true}
			>
				<Scrolling mode="standard" />
				<Paging enabled={false} />
				<SearchPanel visible={true} />
				<Editing
					mode="form"
					allowUpdating={true}
					allowAdding={true}
					//allowDeleting={true}
					useIcons={true}
					newRowPosition="first"
					refreshMode="reshape"
				/>
				<Column
					dataField="id"
					caption="#"
					width={50}
					allowEditing={false}
					defaultSortIndex={0}
					defaultSortOrder="desc"
				/>
				<Column
					dataField="dateCreate"
					dataType="date"
					caption="Дата брони"
					allowEditing={false}
					visible={false}
				/>
				<Column dataField="clientId" caption="Клиент" width={250}>
					<Lookup
						dataSource={clients}
						valueExpr="id"
						displayExpr={(data: Client) => {
							if (!data) return ''

							return [
								data.lastName,
								data.firstName,
								data.patronymicName,
								'/',
								data.dateBirth ? data.dateBirth.toLocaleDateString() : '----',
							].join(' ')
						}}
					/>
				</Column>
				<Column dataField="cityId" caption="Город">
					<Lookup dataSource={cities} valueExpr="id" displayExpr="name" />
				</Column>
				<Column dataField="managerId" caption="Менеджер" visible={false}>
					<Lookup
						dataSource={managers}
						valueExpr="id"
						displayExpr={(data: Manager) => {
							if (!data) return ''

							return [data.lastName, data.firstName, data.patronymicName].join(
								' ',
							)
						}}
					/>
				</Column>
				<Column dataField="stateId" caption="Статус">
					<Lookup dataSource={rentStates} valueExpr="id" displayExpr="name" />
				</Column>
				<Column caption="Даты аренды">
					<Column dataField="dateFrom" dataType="date" caption="Начало" />
					<Column dataField="dateTo" dataType="date" caption="Завершение" />
				</Column>
				<Column caption="Суммы">
					<Column
						dataField="price"
						dataType="number"
						caption="Оплачено"
						width={50}
					/>
					<Column
						dataField="deposit"
						dataType="number"
						caption="Депозит"
						width={50}
					/>
				</Column>
				<Column
					dataField="deliveryAddress"
					dataType="string"
					caption="Адрес доставки"
					visible={false}
				>
					<FormItem
						colSpan={2}
						editorType="dxTextArea"
						editorOptions={{ height: 100 }}
					/>
				</Column>
				{
					<Column type="buttons" width={75}>
						<Button name="edit" />
						<Button name="delete" />
						<Button
							hint="Распечатать договор"
							icon="doc"
							//visible={this.isCloneIconVisible}
							//disabled={this.isCloneIconDisabled}
							onClick={onPrintContract}
						/>
					</Column>
				}
				<MasterDetail enabled={true} component={RentDetails} />
			</DataGrid>
		</Page>
	)
}
