import { useRef, useState } from 'react'

interface DownloadFileProps {
	//readonly apiDefinition: (obj?: any) => Promise<AxiosResponse<Blob>>
	readonly apiDefinition: (data?: any) => Promise<Blob>
	readonly preDownloading: () => void
	readonly postDownloading: () => void
	readonly onError: () => void
	readonly getFileName: (data?: any) => string
}

interface DownloadedFileInfo {
	readonly download: (obj?: any) => Promise<void>
	readonly ref: React.MutableRefObject<HTMLAnchorElement | null>
	readonly name: string | undefined
	readonly url: string | undefined
}

export const useDownloadFile = ({
	apiDefinition,
	preDownloading,
	postDownloading,
	onError,
	getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
	const ref = useRef<HTMLAnchorElement | null>(null)
	const [url, setFileUrl] = useState<string>()
	const [name, setFileName] = useState<string>()

	const download = async (data?: any) => {
		try {
			preDownloading()
			//const { data } = await apiDefinition(obj)
			//const url = URL.createObjectURL(new Blob([data]))

			const blobData = await apiDefinition(data)
			const url = URL.createObjectURL(blobData)

			setFileUrl(url)
			setFileName(getFileName(data))
			ref.current?.click()
			postDownloading()
			URL.revokeObjectURL(url)
		} catch (error) {
			onError()
		}
	}

	return { download, ref, url, name }
}
