/** @jsxImportSource @emotion/react */
import { MenuItem, MenuItemLink } from './Styles'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'oidc-react'
import { UserIcon } from './Icons'
import { SignInLink } from './SignInLink'

export const SignIn = () => {
	const authState = useAuth()

	const handleSignOutClick = () => {
		//удаляет токен только на клиенте
		//authState.signOut()

		//удаляет токен на клиенте и сервере
		authState.signOutRedirect()
	}

	return (
		<>
			{authState && authState.userData && (
				<>
					<li key="account" css={MenuItem}>
						<Link css={MenuItemLink} to="/account">
							<UserIcon />
							{/*authState.userData.profile.name*/}
							My Profile
						</Link>
					</li>
					<li key="signout" css={MenuItem}>
						<div css={MenuItemLink} onClick={handleSignOutClick}>
							<span>Sign Out</span>
						</div>
					</li>
				</>
			)}
			{(!authState || !authState.userData) && (
				<>
					<li key="signin" css={MenuItem}>
						<SignInLink style={MenuItemLink}>
							<UserIcon />
							<span>Sign In</span>
						</SignInLink>
					</li>
				</>
			)}
		</>
	)
}
