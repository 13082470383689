import { Page } from '.././Page'
import { Uri } from '../../AppSettings'
import DataGrid, {
	Column,
	Editing,
	Export,
	FormItem,
	Paging,
	Scrolling,
	SearchPanel,
} from 'devextreme-react/data-grid'
import { useAuth } from 'oidc-react'
import CustomStore from 'devextreme/data/custom_store'
import 'whatwg-fetch'
import { Game } from '../../components/Data/Types'
import {
	RequestGet,
	RequestPatch,
	RequestPost,
} from '../../components/Data/Functions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { AppState } from '../../store/Store'
import {
	gettingAllGamesAction,
	gotAllGamesAction,
} from '../../store/GamesReducer'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { exportDataGrid } from 'devextreme/excel_exporter'

export const GamesPage = () => {
	const authState = useAuth()
	const getUser = async () =>
		await authState.userManager.getUser().then(user => user)

	const dispatch = useDispatch()
	const games = useSelector((state: AppState) => state.games.all)

	useEffect(() => {
		const getData = async () => {
			dispatch(gettingAllGamesAction())
			const data = await RequestGet<Game[]>(
				Uri.Games,
				response => response.data,
			)
			dispatch(gotAllGamesAction(data))
		}

		getData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onInitNewRow = (e: any) => {
		//галочка по умолчанию активна
		e.data.isActive = true
	}

	const onExporting = (e: any) => {
		const workbook = new Workbook()
		const worksheet = workbook.addWorksheet('Main')
		e.component.columnOption('isActive', 'visible', false)

		exportDataGrid({
			component: e.component,
			worksheet,
			autoFilterEnabled: true,
		}).then(() => {
			workbook.xlsx.writeBuffer().then(buffer => {
				saveAs(
					new Blob([buffer], { type: 'application/octet-stream' }),
					'Игры PlayStation.xlsx',
				)
			})
		})

		e.component.columnOption('isActive', 'visible', true)
		e.cancel = true
	}

	//тут хорошее описание работы с редактором
	//https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/React/Light/
	const gamesStore = new CustomStore({
		key: 'id',
		loadMode: 'raw',
		load: () => games,
		insert: async value => {
			const user = await getUser()

			if (user) {
				return RequestPost<Game>(
					Uri.Games,
					value,
					undefined,
					undefined,
					undefined,
					user.access_token,
				)
			}
		},
		update: async (key, value) => {
			const user = await getUser()

			if (user) {
				return RequestPatch<Game>(
					`${Uri.Games}/${key}`,
					response => response.data,
					value,
					user.access_token,
				)
			}
		},
		remove: async key => {},
	})

	return (
		<Page title="Список игр PlayStation">
			<DataGrid
				dataSource={gamesStore}
				allowColumnReordering={true}
				allowColumnResizing={true}
				columnResizingMode="widget"
				hoverStateEnabled={true}
				onInitNewRow={onInitNewRow}
				onExporting={onExporting}
			>
				<Scrolling mode="standard" />
				<Paging enabled={false} />
				<SearchPanel visible={true} />
				<Export enabled={true} allowExportSelectedData={true} />
				<Editing
					mode="form"
					allowUpdating={true}
					allowAdding={true}
					//allowDeleting={true}
					useIcons={true}
					newRowPosition="first"
					refreshMode="reshape"
				/>
				<Column
					dataField="id"
					caption="#"
					width={50}
					allowEditing={false}
					defaultSortIndex={0}
					defaultSortOrder="desc"
				/>
				<Column caption="Название">
					<Column dataField="name" dataType="string" caption="English" />
					<Column dataField="nameRu" dataType="string" caption="Русский" />
				</Column>
				<Column caption="Игроков">
					<Column
						dataField="playersLocal"
						dataType="number"
						caption="Экран"
						width={75}
					/>
					<Column
						dataField="playersOnline"
						dataType="number"
						caption="Сеть"
						width={75}
					/>
				</Column>
				<Column
					dataField="description"
					dataType="string"
					caption="Описание"
					visible={false}
				>
					<FormItem
						colSpan={2}
						editorType="dxTextArea"
						editorOptions={{ height: 100 }}
					/>
				</Column>
				<Column
					dataField="isActive"
					dataType="boolean"
					caption="Активна"
					width={75}
				/>
			</DataGrid>
		</Page>
	)
}
