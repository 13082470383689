/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Page } from './Page'
import { ExternalLink } from 'react-external-link'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { YetAnotherLink } from '../components/Styles'

export const FaqPage = () => (
	<Page title="FAQ">
		<div
			css={css`
				margin: 20px 0 0;
			`}
		>
			<h5
				css={css`
					font-size: 14px;
					margin: 0;
				`}
			>
				I want to buy JetSwap credits throught WEWEC.COM. How long should I wait
				for enrollment credits to my account?
			</h5>
			<p
				css={css`
					color: #666;
					margin: 10px 20px;
				`}
			>
				Credits will be sent to your account immediately after payment of the
				application. Immediately make sure that you received your amount of
				credits. Otherwise, please contact our{' '}
				<HashLink css={YetAnotherLink} smooth to="#contacts">
					Customer Service
				</HashLink>
				.
			</p>
		</div>
		<div
			css={css`
				margin: 20px 0 0;
			`}
		>
			<h5
				css={css`
					font-size: 14px;
					margin: 0;
				`}
			>
				I'm want to sell JetSwap credits. How quickly the application will be
				processed?
			</h5>
			<p
				css={css`
					color: #666;
					margin: 10px 20px;
				`}
			>
				Sell orders are also processed immediately. After the completion of the
				sell transaction, make sure that the money is credited to your account
				in full.
			</p>
		</div>
		<div
			css={css`
				margin: 20px 0 0;
			`}
		>
			<h5
				css={css`
					font-size: 14px;
					margin: 0;
				`}
			>
				What payment services operates WEWEC.COM?
			</h5>
			<p
				css={css`
					color: #666;
					margin: 10px 20px;
				`}
			>
				WEWEC.COM service uses{' '}
				<ExternalLink css={YetAnotherLink} href="https://yoomoney.ru">
					YooMoney
				</ExternalLink>
				,{' '}
				<ExternalLink
					css={YetAnotherLink}
					href="http://payeer.com/?partner=296"
				>
					Payeer
				</ExternalLink>
				. You can buy and sell credits more than 100 ways.
			</p>
		</div>
		<div
			css={css`
				margin: 20px 0 0;
			`}
		>
			<h5
				css={css`
					font-size: 14px;
					margin: 0;
				`}
			>
				I want to advertise my website at JetSwap network. What do I need to it?
			</h5>
			<p
				css={css`
					color: #666;
					margin: 10px 20px;
				`}
			>
				First of all, you need to{' '}
				<ExternalLink
					css={YetAnotherLink}
					href="http://go.jetswap.com/reg?r=makedonsky"
				>
					sign up at JetSwap
				</ExternalLink>
				, if you have not already done so. After, you need to{' '}
				<Link css={YetAnotherLink} to="/buy">
					buy JetSwap credits
				</Link>{' '}
				where you wish (it can be at WEWEC.COM). With a credits, you can add
				your site in promotion network or distribution of advertise Email to
				users of the JetSwap system. This can be done directly in your JetSwap
				system account in 'Advertising'.
			</p>
		</div>
		<div
			css={css`
				margin: 20px 0 0;
			`} /*anchor="emailconfirm"*/
		>
			<h5
				css={css`
					font-size: 14px;
					margin: 0;
				`}
			>
				Why do I need to register my Email on the service WEWEC.COM?
			</h5>
			<p
				css={css`
					color: #666;
					margin: 10px 20px;
				`}
			>
				To perform operations on the site WEWEC.COM we ask our users to specify
				a valid Email. It is necessary to control operations and improved
				safety.
			</p>
			<p
				css={css`
					color: #666;
					margin: 10px 20px;
					border-left: 3px solid #d33c14;
					padding: 5px 10px;
				`}
			>
				If you use your Email on our website for the first time - it must be
				confirmed. To the specified address we will send for new users a message
				which contains a link to confirm their Email.
				<br />
				<br />
				<b>This operation is sufficient to carry out one time</b> - in the
				future we will not bother you for Email confirming.
			</p>
			<p
				css={css`
					color: #666;
					margin: 10px 20px;
				`}
			>
				Also, you will be able to receive Email messages with information on our
				service, events in JetSwap. Newsletter can be disabled by using a link
				at the bottom of letter.
			</p>
		</div>
		<div
			css={css`
				margin: 20px 0 0;
			`} /*anchor="buy-limits"*/
		>
			<h5
				css={css`
					font-size: 14px;
					margin: 0;
				`}
			>
				How to increase purchase limit?
			</h5>
			<p
				css={css`
					color: #666;
					margin: 10px 20px;
				`}
			>
				We set for our users individual purchase limits. It means, that user
				can't buy credits by amount greater than he will see at buy process. But
				permitted to make unlimited number of repeat purchases.
				<br />
				<br />
				For increase purchase limits, must:
			</p>
			<ol
				css={css`
					color: #666;
					margin: 10px 20px;
				`}
			>
				<li>
					Regularly (at least a month) make purchases within established limit.
				</li>
				<li>
					Send order to{' '}
					<HashLink css={YetAnotherLink} to="#contacts">
						Customer Service
					</HashLink>{' '}
					for increase purchase limit. Specify in order a name of the payment
					system (for example, Yandex.Money) and Email, which are used in
					purchase transactions.
				</li>
			</ol>
			<p></p>
		</div>
	</Page>
)
