/** @jsxImportSource @emotion/react */
import React from 'react'
import { useAuth } from 'oidc-react'
import { YetAnotherLink } from './Styles'
import { SerializedStyles } from '@emotion/react'

interface Props {
	children: React.ReactNode
	style?: SerializedStyles
}

export const SignInLink = ({ children, style = YetAnotherLink }: Props) => {
	const authState = useAuth()

	const handleSignInClick = () => {
		authState.signIn()
	}

	return (
		<span css={style} onClick={handleSignInClick}>
			{children}
		</span>
	)
}
