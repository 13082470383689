import { Device } from '../components/Data/Types'

export interface DevicesState {
	readonly loading: boolean
	readonly all: Device[]
}

export const GETTINGALLDEVICES = 'GettingAllDevices'
export const GOTGALLDEVICES = 'GotAllDevices'

export const gettingAllDevicesAction = () =>
	({ type: GETTINGALLDEVICES } as const)
export const gotAllDevicesAction = (data: Device[]) =>
	({ type: GOTGALLDEVICES, devices: data } as const)

type DevicesActions =
	| ReturnType<typeof gettingAllDevicesAction>
	| ReturnType<typeof gotAllDevicesAction>

const initialRentsState: DevicesState = {
	loading: false,
	all: [],
}

export const devicesReducer = (
	state = initialRentsState,
	action: DevicesActions,
) => {
	switch (action.type) {
		case GETTINGALLDEVICES: {
			return { ...state, loading: true }
		}
		case GOTGALLDEVICES: {
			return { ...state, all: action.devices, loading: false }
		}
		default:
			return state
	}
}
