/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useParams, useSearchParams } from 'react-router-dom'
import { Uri } from '../AppSettings'
import {
	Button,
	LoadIndicator,
	Popup,
	TextBox,
	ValidationSummary,
	Validator,
} from 'devextreme-react'
import { RequiredRule } from 'devextreme-react/validator'
import { Page } from './Page'
import { Link } from 'react-router-dom'
import { BotDetectCaptcha, getCaptchaId } from '../components/BotDetectCaptcha'
import { YetAnotherLink } from '../components/Styles'
import { RequestError } from '../components/Data/Types'
import { ToolbarItem } from 'devextreme-react/select-box'
import { useAuth } from 'oidc-react'
import { RequestPost } from '../components/Data/Functions'

export const SignInPage = () => {
	const { signAction } = useParams()

	const authState = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		if (signAction === 'callback') {
			authState.userManager
				.signinRedirectCallback()
				.then(function () {
					navigate('/account')
				})
				.catch(ex => {
					console.error(ex)
				})
		} else if (signAction === 'silentcallback') {
			authState.userManager.signinSilentCallback()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [captchaValue, setCaptchaValue] = useState<string | null>(null)
	const [captchaIsValid, setCaptchaIsValid] = useState(true)

	const a = (value: string) => {
		setCaptchaValue(value)
	}

	const b = (value: boolean) => {
		setCaptchaIsValid(value)
	}

	const [popupVisible, setPopupVisible] = useState<boolean>(false)
	const [popupContent, setPopupContent] = useState<
		React.ReactNode | string | null
	>()
	const hidePopup = () => {
		setPopupVisible(false)
	}

	const [loading, setLoading] = useState(false)
	const [searchParams] = useSearchParams()
	const [login, setLogin] = useState<string>(
		localStorage.getItem('accountLogin') ?? '',
	)
	const [password, setPassword] = useState<string>()

	const handleAuthenticateClick = async () => {
		let returnUrl = searchParams.get('returnUrl')

		if (!returnUrl) {
			navigate('/')
		}

		const value = {
			username: login,
			password: password,
			rememberLogin: true,
			returnUrl: returnUrl,
			captchaId: getCaptchaId(),
			captchaValue: captchaValue,
		}

		localStorage.setItem('accountLogin', login)
		setLoading(true)

		RequestPost<any>(
			Uri.BackendSignIn,
			value,
			response => {
				const captchaValid = response.data.captchaValid as boolean

				setCaptchaIsValid(captchaValid)

				if (captchaValid) {
					window.location = response.data.redirectUrl
					//window.location.href = res.data.redirectUrl
				}
			},
			ex => {
				if (ex.response) {
					// client received an error response (5xx, 4xx)
					if (ex.response.status === 400) {
						const errors: RequestError[] = ex.response.data.errors

						if (errors) {
							setPopupContent(
								<ul>
									{errors.map((value, index) => (
										<li key={index}>{value.message}</li>
									))}
								</ul>,
							)
						}
					} else {
						setPopupContent('Unprocessed error. Please, contact us.')
					}

					setPopupVisible(true)
				} else if (ex.request) {
					// client never received a response, or request never left
				} else {
					// anything else
					//throw new Error(ex)
				}

				//выключаем загрузчик только в случае возникновения ошибки (потомучто редирект)
				setLoading(false)
			},
			undefined,
		)
	}

	return loading ? (
		<LoadIndicator id="large-indicator" height={60} width={60} />
	) : (
		<Page title="Sign In">
			<Popup
				visible={popupVisible}
				onHiding={hidePopup}
				dragEnabled={false}
				closeOnOutsideClick={true}
				showCloseButton={true}
				showTitle={true}
				title="Information"
				container=".dx-viewport"
				width={300}
				height={280}
			>
				<ToolbarItem
					widget="dxButton"
					toolbar="bottom"
					location="after"
					options={{
						text: 'OK',
						onClick: hidePopup,
					}}
				/>
				{popupContent}
			</Popup>
			<div
				className="dx-fieldset"
				css={css`
					margin: 0;
					width: 100%;
				`}
			>
				<div className="dx-field ">
					<div className="dx-field-value">
						<TextBox
							width="300"
							placeholder="Enter Email or mobile phone number"
							defaultValue={login}
							name="login"
							maxLength="50"
							onValueChanged={(e: any) => setLogin(e.value)}
						>
							<Validator>
								<RequiredRule message="Email or mobile phone field is required" />
							</Validator>
						</TextBox>
					</div>
					<div
						className="dx-field-label"
						css={css`
							text-align: right;
						`}
					>
						Email or mobile phone number
					</div>
				</div>
				<div className="dx-field ">
					<div className="dx-field-value">
						<TextBox
							width="300"
							mode="password"
							placeholder="Enter password"
							defaultValue={password}
							name="password"
							maxLength="50"
							onValueChanged={(e: any) => setPassword(e.value)}
						>
							<Validator>
								<RequiredRule message="Password is required" />
							</Validator>
						</TextBox>
					</div>
					<div
						className="dx-field-label"
						css={css`
							text-align: right;
						`}
					>
						Password
					</div>
				</div>
				<div className="dx-field ">
					<div>
						<div className="some-page-wrapper">
							<div
								className="dx-field-value"
								css={css`
									display: flex;
									flex-direction: row;
									flex-wrap: wrap;
								`}
							>
								<BotDetectCaptcha isValid={captchaIsValid} a={a} b={b} />
							</div>
						</div>
					</div>
					<div
						className="dx-field-label"
						css={css`
							text-align: right;
						`}
					>
						Enter CAPTCHA
					</div>
				</div>
				<ValidationSummary id="summary" />
			</div>
			<Button
				text="Sign In"
				type="success"
				stylingMode="contained"
				onClick={handleAuthenticateClick}
			/>
			<p
				css={css`
					display: flex;
					justify-content: space-evenly;
				`}
			>
				<Link css={YetAnotherLink} to="/passwordreset">
					Forgot your password?
				</Link>
				<Link css={YetAnotherLink} to="/signup">
					Create new account
				</Link>
			</p>
		</Page>
	)
}
