import { useAuth } from 'oidc-react'
import { useEffect, useState } from 'react'

interface Props {
	role?: string
	children: React.ReactNode
}

export const AuthorizedArea = ({ role, children }: Props) => {
	const [allow, setAllow] = useState<boolean>(false)
	const authState = useAuth()

	const getUser = async () =>
		await authState.userManager.getUser().then(user => user)

	useEffect(() => {
		const doGet = async () => {
			const user = await getUser()

			if ((!user || !user.profile.role) && !role) {
				setAllow(true)
			}

			//todo неправильная логика определения администратора + не работает при отрисовке внутри компонентов devexpress
			if (user) {
				const allow =
					!role ||
					user.profile.role === role ||
					(Array.isArray(user.profile.role) && user.profile.role.includes(role))

				setAllow(allow)
			}
		}
		doGet()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	//todo сделать красиво, чтобы на страницах к которым нет доступа отображалась NotFoundPage, а если это элемент, то просто пустота
	//return allow ? <>{children}</> : <NotFoundPage></NotFoundPage>
	return allow ? <>{children}</> : <></>
}
