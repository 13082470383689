import { useEffect, useState } from 'react'
import { useAuth } from 'oidc-react'
import { Page } from './Page'
import { Uri } from '../AppSettings'
import { DataGrid } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import { RequestGet } from '../components/Data/Functions'

interface LastOrder {
	id: number
	date: Date
	type: string
	credits: number
	money: number
	currency: string
	status: string
}

export const AccountPage = () => {
	const authState = useAuth()
	const [lastOrders, setLastOrders] = useState<LastOrder[]>([])

	useEffect(() => {
		RequestGet<LastOrder[]>(Uri.SapOrdersLast, response => {
			setLastOrders(response.data)
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Page title="Account">
			{(!authState || !authState.userData) && (
				<>
					<div>Process...</div>
					<p>Not Authenticated, please wait 10 seconds or click SignIn again</p>
				</>
			)}
			{lastOrders && (
				<>
					<h3>Jetswap deals</h3>
					<DataGrid
						dataSource={lastOrders}
						allowColumnReordering={true}
						hoverStateEnabled={true}
					>
						<Column dataField="id" dataType="number" caption="Id" />
						<Column dataField="date" dataType="date" caption="Date" />
						<Column dataField="type" dataType="string" caption="Type" />
						<Column dataField="credits" dataType="number" caption="Credits" />
						<Column dataField="money" dataType="number" caption="Money" />
						<Column dataField="currency" dataType="string" caption="Currency" />
						<Column dataField="status" dataType="string" caption="Status" />
					</DataGrid>
				</>
			)}
		</Page>
	)
}
