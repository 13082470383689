import { RentDevice } from '../components/Data/Types'

export interface RentDevicesState {
	readonly loading: boolean
	readonly all: RentDevice[]
}

export const GETTINGALLRENTDEVICES = 'GettingAllRentDevices'
export const GOTGALLRENTDEVICES = 'GotAllRentDevices'

export const gettingAllRentDevicesAction = () =>
	({ type: GETTINGALLRENTDEVICES } as const)
export const gotAllRentDevicesAction = (data: RentDevice[]) =>
	({ type: GOTGALLRENTDEVICES, rentDevices: data } as const)

type RentDevicesActions =
	| ReturnType<typeof gettingAllRentDevicesAction>
	| ReturnType<typeof gotAllRentDevicesAction>

const initialRentsState: RentDevicesState = {
	loading: false,
	all: [],
}

export const rentDevicesReducer = (
	state = initialRentsState,
	action: RentDevicesActions,
) => {
	switch (action.type) {
		case GETTINGALLRENTDEVICES: {
			return { ...state, loading: true }
		}
		case GOTGALLRENTDEVICES: {
			return { ...state, all: action.rentDevices, loading: false }
		}
		default:
			return state
	}
}
