import { DataGrid, TabPanel } from 'devextreme-react'
import {
	Column,
	Editing,
	Lookup,
	Paging,
	SearchPanel,
} from 'devextreme-react/data-grid'
import { Item } from 'devextreme-react/tab-panel'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import { useAuth } from 'oidc-react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Uri } from '../AppSettings'
import {
	gettingAllAccountGamesAction,
	gotAllAccountGamesAction,
} from '../store/AccountGamesReducer'
import {
	gettingAllDevicesAction,
	gotAllDevicesAction,
} from '../store/DevicesReducer'
import {
	gettingAllRentAccountGamesAction,
	gotAllRentAccountGamesAction,
} from '../store/RentAccountGamesReducer'
import {
	gettingAllRentDevicesAction,
	gotAllRentDevicesAction,
} from '../store/RentDevicesReducer'
import { AppState } from '../store/Store'
import { RequestGet, RequestPatch, RequestPost } from './Data/Functions'
import {
	AccountGameFull,
	Device,
	Rent,
	RentAccountGame,
	RentDevice,
} from './Data/Types'

interface Props {
	data: { data: Rent }
}

export const RentDetails = ({ data: { data: rent } }: Props) => {
	const authState = useAuth()
	const getUser = async () =>
		await authState.userManager.getUser().then(user => user)

	const dispatch = useDispatch()
	const accountGames = useSelector((state: AppState) => state.accountGames.all)
	const devices = useSelector((state: AppState) => state.devices.all)
	const rentDevices = useSelector((state: AppState) => state.rentDevices.all)
	const rentAccountGames = useSelector(
		(state: AppState) => state.rentAccountGames.all,
	)

	useEffect(() => {
		const getAccountGamesData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllAccountGamesAction())
				const data = await RequestGet<AccountGameFull[]>(
					`${Uri.AccountGames}?full=true`,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllAccountGamesAction(data))
			}
		}

		const getDevicesData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllDevicesAction())
				const data = await RequestGet<Device[]>(
					Uri.Devices,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllDevicesAction(data))
			}
		}

		const getRentDevicesData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllRentDevicesAction())
				const data = await RequestGet<RentDevice[]>(
					Uri.RentDevices,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllRentDevicesAction(data))
			}
		}

		const getRentAccountGamesData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllRentAccountGamesAction())
				const data = await RequestGet<RentAccountGame[]>(
					Uri.RentAccountGames,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllRentAccountGamesAction(data))
			}
		}

		getDevicesData()
		getRentDevicesData()
		getAccountGamesData()
		getRentAccountGamesData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const rentDevicesStore = new CustomStore({
		key: 'id',
		loadMode: 'raw',
		load: () => rentDevices,
		insert: async value => {
			const user = await getUser()

			if (user) {
				return RequestPost<RentDevice>(
					Uri.RentDevices,
					{ ...value, rentId: rent.id },
					undefined,
					undefined,
					undefined,
					user.access_token,
				)
			}
		},
		update: async (key, value) => {
			const user = await getUser()

			if (user) {
				return RequestPatch<RentDevice>(
					`${Uri.RentDevices}/${key}`,
					response => response.data,
					{ ...value, rentId: rent.id },
					user.access_token,
				)
			}
		},
		remove: async key => {},
	})

	const rentAccountGamesStore = new CustomStore({
		key: 'id',
		loadMode: 'raw',
		load: () => rentAccountGames,
		insert: async value => {
			const user = await getUser()

			if (user) {
				return RequestPost<RentAccountGame>(
					Uri.RentAccountGames,
					{ ...value, rentId: rent.id },
					undefined,
					undefined,
					undefined,
					user.access_token,
				)
			}
		},
		update: async (key, value) => {
			const user = await getUser()

			if (user) {
				return RequestPatch<RentAccountGame>(
					`${Uri.RentAccountGames}/${key}`,
					response => response.data,
					{ ...value, rentId: rent.id },
					user.access_token,
				)
			}
		},
		remove: async key => {},
	})

	const getRentDevices = (rentId: number) => {
		console.log(rentId)
		return new DataSource({
			store: rentDevicesStore,
			filter: ['rentId', '=', rentId],
		})
	}

	const getRentAccountGames = (rentId: number) => {
		console.log(rentId)
		return new DataSource({
			store: rentAccountGamesStore,
			filter: ['rentId', '=', rentId],
		})
	}

	return (
		<TabPanel>
			<Item title="Оборудование">
				<DataGrid dataSource={getRentDevices(rent.id)} showBorders={false}>
					<Editing
						mode="form"
						allowUpdating={true}
						allowAdding={true}
						//allowDeleting={true}
						useIcons={true}
						newRowPosition="first"
						refreshMode="reshape"
					/>
					<SearchPanel visible={true} />
					<Paging defaultPageSize={25} />
					<Column dataField="deviceId" caption="Оборудование">
						<Lookup
							dataSource={devices}
							valueExpr="id"
							displayExpr={(obj: Device) =>
								`${obj.typeName} ${obj.serialNumber} (${obj.description})`
							}
						/>
					</Column>
					<Column
						dataField="dateCreate"
						dataType="date"
						caption="Дата"
						allowEditing={false}
						width={100}
					></Column>
				</DataGrid>
			</Item>
			<Item title="Игры">
				<DataGrid dataSource={getRentAccountGames(rent.id)} showBorders={false}>
					<Editing
						mode="form"
						allowUpdating={true}
						allowAdding={true}
						//allowDeleting={true}
						useIcons={true}
						newRowPosition="first"
						refreshMode="reshape"
					/>
					<SearchPanel visible={true} />
					<Paging defaultPageSize={25} />
					<Column dataField="accountGameId" caption="Наименование">
						<Lookup
							dataSource={accountGames}
							valueExpr="id"
							displayExpr={(obj: AccountGameFull) =>
								`${obj.gameName} ${
									obj.gameNameRu ? `/ ${obj.gameNameRu}` : ``
								} (${obj.accountName})`
							}
						/>
					</Column>
					<Column
						dataField="dateCreate"
						dataType="date"
						caption="Дата"
						allowEditing={false}
						width={100}
					></Column>
				</DataGrid>
			</Item>
		</TabPanel>
	)
}
