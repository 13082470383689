import { useAuth } from 'oidc-react'
import { Page } from './Page'

export const AuthorizedPage: React.FC = ({ children }) => {
	const authState = useAuth()
	const isAuthenticated = authState && authState.userData

	if (isAuthenticated) {
		return <>{children}</>
	}

	return <Page title="You don't have access to this page">{null}</Page>
}
