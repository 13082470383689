import { AccountGameFull } from '../components/Data/Types'

export interface AccountGamesState {
	readonly loading: boolean
	readonly all: AccountGameFull[]
}

export const GETTINGALLACCOUNTGAMES = 'GettingAllAccountGames'
export const GOTGALLACCOUNTGAMES = 'GotAllAccountGames'

export const gettingAllAccountGamesAction = () =>
	({ type: GETTINGALLACCOUNTGAMES } as const)
export const gotAllAccountGamesAction = (data: AccountGameFull[]) =>
	({ type: GOTGALLACCOUNTGAMES, accountGames: data } as const)

type AccountGamesActions =
	| ReturnType<typeof gettingAllAccountGamesAction>
	| ReturnType<typeof gotAllAccountGamesAction>

const initialRentsState: AccountGamesState = {
	loading: false,
	all: [],
}

export const accountGamesReducer = (
	state = initialRentsState,
	action: AccountGamesActions,
) => {
	switch (action.type) {
		case GETTINGALLACCOUNTGAMES: {
			return { ...state, loading: true }
		}
		case GOTGALLACCOUNTGAMES: {
			return { ...state, all: action.accountGames, loading: false }
		}
		default:
			return state
	}
}
