export const server =
	process.env.REACT_APP_ENV === 'production'
		? 'https://wewec.com'
		: process.env.REACT_APP_ENV === 'staging'
		? 'https://wewec.com'
		: 'https://localhost:5000'

export const oidcServer =
	process.env.REACT_APP_ENV === 'production'
		? 'https://wewec.com'
		: process.env.REACT_APP_ENV === 'staging'
		? 'https://wewec.com'
		: 'https://localhost:5000'

export enum Uri {
	SapOrders = '/saporders',
	SapOrdersParams = '/saporders/params',
	SapOrdersStatus = '/saporders/status',
	SapOrdersLast = '/saporders/last',

	RedirectAfterSignIn = '/account',
	RedirectAfterSignOut = '/',

	Rents = '/rents',
	RentStates = '/rentstates',
	RentDevices = '/rentdevices',
	RentAccountGames = '/rentaccountgames',
	Games = '/games',
	Clients = '/clients',
	Devices = '/devices',
	Accounts = '/accounts',
	AccountGames = '/accountgames',
	Cities = '/cities',
	Managers = '/managers',

	SignUp = '/account/signup',
	SignUpByHash = '/account/signupbyhash',
	PasswordResetByHash = '/account/passwordresetbyhash',
	BackendSignIn = '/account/login',
	PasswordReset = '/account/passwordreset',
}

export enum IdentityRoles {
	USER = 'user',
	PS_MANAGER = 'ps_manager',
}

export enum CurrencySymbol {
	RUB = '\u20bd',
	USD = '\u0024',
	EUR = '\u20ac',
}

//todo лимиты есть в БД, нужно вынести отсюда
export enum DealLimits {
	MinAmount = 5000,
	MaxAmount = 5000000,
}

export const JetswapPostUrl: string = '//go.jetswap.com/payment'
export const JetswapSiteId: number = 255

export const YooMoneyPayWalletAddress: number = 41001719602938
export const YooMoneySuccessUrl: string = '//wewec.com'
export const YooMoneyPostUrl: string = '//yoomoney.ru/quickpay/confirm.xml'
