import { Manager } from '../components/Data/Types'

export interface ManagersState {
	readonly loading: boolean
	readonly all: Manager[]
}

export const GETTINGALLMANAGERS = 'GettingAllManagers'
export const GOTGALLMANAGERS = 'GotAllManagers'

export const gettingAllManagersAction = () =>
	({ type: GETTINGALLMANAGERS } as const)
export const gotAllManagersAction = (data: Manager[]) =>
	({ type: GOTGALLMANAGERS, managers: data } as const)

type ManagersActions =
	| ReturnType<typeof gettingAllManagersAction>
	| ReturnType<typeof gotAllManagersAction>

const initialRentsState: ManagersState = {
	loading: false,
	all: [],
}

export const managersReducer = (
	state = initialRentsState,
	action: ManagersActions,
) => {
	switch (action.type) {
		case GETTINGALLMANAGERS: {
			return { ...state, loading: true }
		}
		case GOTGALLMANAGERS: {
			return { ...state, all: action.managers, loading: false }
		}
		default:
			return state
	}
}
