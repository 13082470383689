import { Page } from '../Page'
import { Uri } from '../../AppSettings'
import DataGrid, {
	Column,
	Editing,
	Paging,
	Scrolling,
	SearchPanel,
} from 'devextreme-react/data-grid'
import { useAuth } from 'oidc-react'
import CustomStore from 'devextreme/data/custom_store'
import 'whatwg-fetch'
import { Device } from '../../components/Data/Types'
import {
	RequestGet,
	RequestPatch,
	RequestPost,
} from '../../components/Data/Functions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { AppState } from '../../store/Store'
import {
	gettingAllDevicesAction,
	gotAllDevicesAction,
} from '../../store/DevicesReducer'

export const DevicesPage = () => {
	const authState = useAuth()
	const getUser = async () =>
		await authState.userManager.getUser().then(user => user)

	const dispatch = useDispatch()
	const devices = useSelector((state: AppState) => state.devices.all)

	useEffect(() => {
		const getData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllDevicesAction())
				const data = await RequestGet<Device[]>(
					Uri.Devices,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllDevicesAction(data))
			}
		}

		getData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onInitNewRow = (e: any) => {
		//галочка по умолчанию активна
		e.data.isActive = true
	}

	//тут хорошее описание работы с редактором
	//https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/React/Light/
	const devicesStore = new CustomStore({
		key: 'id',
		loadMode: 'raw',
		load: () => devices,
		insert: async value => {
			const user = await getUser()

			if (user) {
				return RequestPost<Device>(
					Uri.Devices,
					value,
					undefined,
					undefined,
					undefined,
					user.access_token,
				)
			}
		},
		update: async (key, value) => {
			const user = await getUser()

			if (user) {
				return RequestPatch<Device>(
					`${Uri.Devices}/${key}`,
					response => response.data,
					value,
					user.access_token,
				)
			}
		},
		remove: async key => {},
	})

	return (
		<Page title="Оборудование">
			<DataGrid
				dataSource={devicesStore}
				allowColumnReordering={true}
				allowColumnResizing={true}
				columnResizingMode="widget"
				hoverStateEnabled={true}
				onInitNewRow={onInitNewRow}
			>
				<Scrolling mode="standard" />
				<Paging enabled={false} />
				<SearchPanel visible={true} />
				<Editing
					mode="form"
					allowUpdating={true}
					allowAdding={true}
					//allowDeleting={true}
					useIcons={true}
					newRowPosition="first"
					refreshMode="reshape"
				/>
				<Column
					dataField="id"
					caption="#"
					width={50}
					allowEditing={false}
					defaultSortIndex={0}
					defaultSortOrder="desc"
				/>
				<Column
					dataField="serialNumber"
					dataType="string"
					caption="Серийный номер"
				/>
				<Column
					dataField="typeName"
					dataType="string"
					caption="Тип устойства"
				/>
				<Column
					dataField="estimatedCost"
					dataType="number"
					caption="Оценочная стоимость"
				/>
				<Column dataField="description" dataType="string" caption="Описание" />
			</DataGrid>
		</Page>
	)
}
