/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState, useRef } from 'react'
import { Page } from './Page'
import {
	InitParams,
	JetswapDealer,
	SapCrossPrice,
	SapWallet,
	PayWallet,
	SapOrderPostResponse,
	RequestError,
} from '../components/Data/Types'
import { TextBox } from 'devextreme-react/text-box'
import { NumberBox } from 'devextreme-react/number-box'
import { SelectBox } from 'devextreme-react/select-box'
import { CheckBox } from 'devextreme-react/check-box'
import Button from 'devextreme-react/button'
import ValidationSummary from 'devextreme-react/validation-summary'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import {
	Validator,
	RequiredRule,
	EmailRule,
	CompareRule,
	//AsyncRule
} from 'devextreme-react/validator'
//https://www.digitalocean.com/community/tutorials/react-axios-react-ru
import { Link } from 'react-router-dom'
import {
	DealLimits,
	Uri,
	YooMoneyPayWalletAddress,
	YooMoneyPostUrl,
	YooMoneySuccessUrl,
} from '../AppSettings'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import { BotDetectCaptcha, getCaptchaId } from '../components/BotDetectCaptcha'
import { Controller, useForm } from 'react-hook-form'
import { RequestGet, RequestPost } from '../components/Data/Functions'

interface FormData {
	payWalletAddress: string
	email: string
	sapLogin: string
	creditsAmount: number

	payWallet: PayWallet
	sapWallet: SapWallet
}

export const BuyPage = () => {
	const labelVisible: boolean = false

	const [captchaValue, setCaptchaValue] = useState<string | null>(null)
	const [captchaIsValid, setCaptchaIsValid] = useState<boolean>(true)

	const a = (value: string) => {
		setCaptchaValue(value)
	}

	const b = (value: boolean) => {
		setCaptchaIsValid(value)
	}

	const myForm = useRef<HTMLFormElement | null>(null)

	const { control, setValue, getValues, watch, handleSubmit } =
		useForm<FormData>({
			defaultValues: JSON.parse(localStorage.getItem('buyForm') ?? '{}'),
		})

	const [sapLogin, email, creditsAmount, payWallet] = watch([
		'sapLogin',
		'email',
		'creditsAmount',
		'payWallet',
	]) // you can supply default value as second argument

	const [moneyAmountWithTax, setMoneyAmountWithTax] = useState(0)
	const [moneyTax, setMoneyTax] = useState(0)
	const [moneyAmount, setMoneyAmount] = useState(0)
	//const [creditsAmountWithTax, setCreditsAmountWithTax] = useState(0)
	const [transactionId, setTransactionId] = useState(0)
	const [loading, setLoading] = useState(true)
	const [popupContent, setPopupContent] = useState<
		React.ReactNode | string | null
	>()
	const [popupVisible, setPopupVisible] = useState<boolean>(false)

	const hidePopup = () => {
		setPopupVisible(false)
	}

	const [initParams, setInitParams] = useState<InitParams>({
		sapCrossPrices: [],
		sapWallets: [],
		payWallets: [],
		jetswapDealers: [],
	})

	const [sapCrossPrice, setSapCrossPrice] = useState<SapCrossPrice>({
		sapWalletTag: '',
		payWalletTag: '',
		currencyCodeDigit: 0,
		currencyCodeLetter: '',
		pricePerAmount: 1,
		buyPrice: 0,
		sellPrice: 0,
	})

	const submitForm = (form: FormData) => {
		let value = {
			sapWalletId: form.sapWallet.id,
			creditsAmount: form.creditsAmount,
			payWalletTag: form.payWallet.tag,
			email: form.email,
			payWalletAddress: form.payWalletAddress,
			sapLogin: form.sapLogin,
			transactionType: 2,

			captchaId: getCaptchaId(),
			captchaValue: captchaValue,
		}

		setLoading(true)

		RequestPost<SapOrderPostResponse>(
			Uri.SapOrders,
			value,
			response => {
				const transaction = response.data

				setCaptchaIsValid(transaction.captchaIsValid)
				setTransactionId(transaction.orderId)

				if (transaction.captchaIsValid) {
					myForm.current && myForm.current.submit()
				}
			},
			ex => {
				if (ex.response) {
					// client received an error response (5xx, 4xx)
					if (ex.response.status === 400) {
						const errors: RequestError[] = ex.response.data.errors

						if (errors) {
							setPopupContent(
								<ul>
									{errors.map((value, index) => (
										<li key={index}>{value.message}</li>
									))}
								</ul>,
							)
						}
					} else {
						setPopupContent('Unprocessed error. Please, contact us.')
					}

					setPopupVisible(true)
				} else if (ex.request) {
					// client never received a response, or request never left
				} else {
					// anything else
					//throw new Error(ex)
				}

				//todo не работает и вроде не нужен после ререндера
				//reloadCaptchaImage()

				//выключаем загрузчик только в случае возникновения ошибки (потомучто редирект)
				setLoading(false)
			},
			undefined,
		)
	}

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			const crossPrice = initParams.sapCrossPrices.find(
				r =>
					r.sapWalletTag === value.sapWallet?.tag &&
					r.payWalletTag === value.payWallet?.tag,
			)

			if (crossPrice) {
				setSapCrossPrice(crossPrice)

				const moneyAmount: number =
					Math.round(
						(((value.creditsAmount ?? 0) * crossPrice.buyPrice) /
							crossPrice.pricePerAmount) *
							100,
					) / 100

				const moneyTax = value.payWallet?.tax
					? Math.round(value.payWallet?.tax * moneyAmount * 100) / 100
					: 0

				//todo эти два способа расчёта суммы с комиссией и без дают разные результаты
				//const moneyAmountWithTax = Math.round(moneyAmount * (1 + this.state.payWallet.tax) * 100) / 100;
				const moneyAmountWithTax = moneyAmount + moneyTax //для яндекса вроде лучше эта
				/*
				const creditsAmountWithTax = value.sapWallet?.tax
					? Math.ceil(value.creditsAmount ?? 0 * (1 + value.sapWallet.tax ?? 0))
					: value.creditsAmount ?? 0
				*/

				setMoneyAmountWithTax(moneyAmountWithTax)
				//setCreditsAmountWithTax(creditsAmountWithTax)
				setMoneyAmount(moneyAmount)
				setMoneyTax(moneyTax)
			}

			localStorage.setItem('buyForm', JSON.stringify(value))
		})

		return () => subscription.unsubscribe()
	}, [watch, initParams])

	useEffect(() => {
		setLoading(true)

		RequestGet<InitParams>(Uri.SapOrdersParams, response => {
			const initParams: InitParams = response.data
			setInitParams(initParams)

			setValue(
				'payWallet',
				initParams.payWallets.find(r => r.id === getValues('payWallet')?.id) ??
					initParams.payWallets[0],
			)

			setValue(
				'sapWallet',
				initParams.sapWallets.find(r => r.id === getValues('sapWallet')?.id) ??
					initParams.sapWallets[0],
			)

			const creditsAmount = getValues('creditsAmount')

			setValue(
				'creditsAmount',
				!creditsAmount || creditsAmount < DealLimits.MinAmount
					? DealLimits.MinAmount
					: creditsAmount,
			)

			setLoading(false)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{loading ? (
				<LoadIndicator id="large-indicator" height={60} width={60} />
			) : (
				<Page title="Buy JetSwap credits">
					<Popup
						visible={popupVisible}
						onHiding={hidePopup}
						dragEnabled={false}
						closeOnOutsideClick={true}
						showCloseButton={true}
						showTitle={true}
						title="Information"
						container=".dx-viewport"
						width={300}
						height={280}
					>
						<ToolbarItem
							widget="dxButton"
							toolbar="bottom"
							location="after"
							options={{
								text: 'OK',
								onClick: hidePopup,
							}}
						/>
						{popupContent}
					</Popup>
					<p>
						To purchase SAP credits fill out the form. All fields are required.
						Be careful! Erroneous transfers and payments can not be undone.
						Please, write your current Email address, it will also receive
						information on the status of transfers and payments.
					</p>
					<form
						css={css`
							width: 100%;
							display: flex;
							flex-direction: column;
						`}
						onSubmit={handleSubmit(submitForm)}
					>
						<div
							className="dx-fieldset"
							css={css`
								margin: 0;
								width: 100%;
							`}
						>
							<div className="dx-field ">
								<div className="dx-field-value">
									<Controller
										control={control}
										name="sapWallet"
										render={({ field: { onChange, value } }) => (
											<SelectBox
												items={initParams.sapWallets}
												//displayExpr="name"
												displayExpr={(obj: SapWallet) => {
													return obj
														? `${obj.name} (${obj.balance} cr.)`
														: '----'
												}}
												defaultValue={value}
												onValueChanged={e => onChange(e.value)}
												label="System of Active Promotion (SAP)"
												labelMode={labelVisible ? 'floating' : 'hidden'}
											/>
										)}
									/>
								</div>
								<div
									className="dx-field-label"
									css={css`
										text-align: right;
									`}
								>
									System of Active Promotion (SAP)
								</div>
							</div>
							<div className="dx-field ">
								<div className="dx-field-value">
									<Controller
										control={control}
										name="payWallet"
										render={({ field: { onChange, value } }) => (
											<SelectBox
												items={initParams.payWallets}
												displayExpr={(obj: PayWallet) => {
													return obj
														? `${obj.name} / ${obj.currencyCodeLetter}`
														: '----'
												}}
												defaultValue={value}
												onValueChanged={e => onChange(e.value)}
												label="Payment system"
												labelMode={labelVisible ? 'floating' : 'hidden'}
											/>
										)}
									/>
								</div>
								<div
									className="dx-field-label"
									css={css`
										text-align: right;
									`}
								>
									Payment system
								</div>
							</div>
							<div className="dx-field ">
								<div className="dx-field-value">
									<Controller
										control={control}
										name="creditsAmount"
										render={({ field: { onChange, value } }) => (
											<NumberBox
												showSpinButtons={true}
												min={DealLimits.MinAmount}
												max={DealLimits.MaxAmount}
												format="#,##0"
												step={500}
												value={value}
												valueChangeEvent="keyup"
												onValueChanged={e => onChange(e.value)}
												placeholder="You buy"
												label="Credits amount"
												labelMode={labelVisible ? 'floating' : 'hidden'}
											/>
										)}
									/>
								</div>
								<div
									className="dx-field-label"
									css={css`
										text-align: right;
									`}
								>
									You buy
								</div>
								<div className="dx-field-value">
									credits. Price is {sapCrossPrice.buyPrice}{' '}
									{sapCrossPrice.currencyCodeLetter} for{' '}
									{sapCrossPrice.pricePerAmount} pcs.
								</div>
							</div>
							<div className="dx-field ">
								<div className="dx-field-value">
									<NumberBox
										format="#,##0.00"
										readOnly={true}
										value={moneyAmountWithTax}
										placeholder="You pay"
										label="Pay amount"
										labelMode={labelVisible ? 'floating' : 'hidden'}
									/>
									{/*
						по формату number box для вставки собственных символов
						https://js.devexpress.com/Demos/WidgetsGallery/Demo/Common/CustomTextEditorButtons/React/Light/ 
						*/}
								</div>
								<div
									className="dx-field-label"
									css={css`
										text-align: right;
									`}
								>
									You pay
								</div>
								<div className="dx-field-value">
									The specified amount after including tax of the payment system
									in the amount: {moneyTax} {payWallet.currencyCodeLetter}
								</div>
							</div>
							<div className="dx-field ">
								<div className="dx-field-value">
									<Controller
										control={control}
										name="sapLogin"
										render={({ field: { onChange, value } }) => (
											<TextBox
												value={value}
												valueChangeEvent="keyup"
												onValueChanged={e => onChange(e.value)}
												placeholder="Enter your JetSwap login"
												label="JetSwap login, recipient"
												labelMode={labelVisible ? 'floating' : 'hidden'}
											>
												<Validator>
													<RequiredRule message="JetSwap login is required" />
												</Validator>
											</TextBox>
										)}
									/>
								</div>
								<div
									className="dx-field-label"
									css={css`
										text-align: right;
									`}
								>
									Your <span className="credsys__name">JetSwap</span> login
								</div>
							</div>
							<div className="dx-field ">
								<div className="dx-field-value">
									<Controller
										control={control}
										name="email"
										render={({ field: { onChange, value } }) => (
											<TextBox
												mode="email"
												showClearButton={true}
												value={value}
												valueChangeEvent="keyup"
												onValueChanged={e => onChange(e.value)}
												placeholder="Enter your email"
												label="Email"
												labelMode={labelVisible ? 'floating' : 'hidden'}
											>
												{/*валидатор взял отсюда https://js.devexpress.com/Demos/WidgetsGallery/Demo/Validation/Overview/React/Light/*/}
												<Validator>
													<RequiredRule message="Email is required" />
													<EmailRule message="Email is invalid" />
													{/* <AsyncRule message="Email is already registered" validationCallback={asyncValidation} /> */}
												</Validator>
											</TextBox>
										)}
									/>
								</div>
								<div
									className="dx-field-label"
									css={css`
										text-align: right;
									`}
								>
									Your Email address
								</div>
							</div>
							<div className="dx-field ">
								<div>
									<div className="some-page-wrapper">
										<div
											className="dx-field-value"
											css={css`
												display: flex;
												flex-direction: row;
												flex-wrap: wrap;
											`}
										>
											<BotDetectCaptcha isValid={captchaIsValid} a={a} b={b} />
										</div>
									</div>
								</div>
								<div
									className="dx-field-label"
									css={css`
										text-align: right;
									`}
								>
									Enter CAPTCHA
								</div>
							</div>
							<div>
								<div>
									<div className="some-page-wrapper">
										<div
											className="dx-field-value"
											css={css`
												display: flex;
												flex-direction: row;
												flex-wrap: wrap;
											`}
										>
											<div
												css={css`
													margin-right: 15px;
												`}
											>
												<CheckBox defaultValue={true}>
													<Validator>
														<CompareRule
															message="You must agree to the Terms and Conditions"
															comparisonTarget={() => {
																return true
															}}
														/>
													</Validator>
												</CheckBox>
											</div>
											<div
												css={css`
													display: flex;
													flex-direction: column;
													flex-basis: 100%;
													flex: 1;
												`}
											>
												<div>
													<span>
														I'VE CHECKED THE ENTERED DATA and agree to the{' '}
														<Link to="/terms" target="_blank">
															terms of service
														</Link>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<ValidationSummary id="summary" />
						</div>
						<p></p>
						<Button
							text="BUY"
							type="success"
							stylingMode="contained"
							useSubmitBehavior={true}
						/>
					</form>
				</Page>
			)}
			<form method="POST" action={YooMoneyPostUrl} ref={myForm}>
				{/*https://yoomoney.ru/docs/payment-buttons/using-api/forms*/}
				<input type="hidden" name="quickpay-form" value="shop" />
				<input type="hidden" name="paymentType" value="PC" />
				<input type="hidden" name="receiver" value={YooMoneyPayWalletAddress} />
				<input type="hidden" name="sum" value={moneyAmount} />
				<input type="hidden" name="successURL" value={YooMoneySuccessUrl} />
				<input type="hidden" name="label" value={transactionId} />
				{/*назначение платежа */}
				<input
					type="hidden"
					name="targets"
					value={'WEWEC.COM#' + transactionId}
				/>
				{/*
				//комментарий покупателя 
				<input
					type="hidden"
					name="comment"
					value={'WEWEC.COM#' + transactionId}
				/>
				*/}
				{/*Если в одних ситуациях контакты нужны, а в других — нет, вы можете изменять значение параметра (value="true" — запрашивать, value="false" — не запрашивать)*/}
				<input type="hidden" name="need-fio" value="false" />
				<input type="hidden" name="need-email" value="false" />
				<input type="hidden" name="need-phone" value="false" />
				<input type="hidden" name="need-address" value="false" />
			</form>
		</>
	)
}
