/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Uri } from '../AppSettings'
import {
	Button,
	LoadIndicator,
	Popup,
	TextBox,
	ValidationSummary,
	Validator,
} from 'devextreme-react'
import {
	CompareRule,
	EmailRule,
	RequiredRule,
} from 'devextreme-react/validator'
import { Page } from './Page'
import { BotDetectCaptcha, getCaptchaId } from '../components/BotDetectCaptcha'
import { Controller, useForm } from 'react-hook-form'
import { ToolbarItem } from 'devextreme-react/popup'
import { useParams } from 'react-router-dom'
import { RequestError } from '../components/Data/Types'
import { SignInLink } from '../components/SignInLink'
import { RequestPost } from '../components/Data/Functions'

interface FormData {
	email: string
	password: string
	passwordConfirm: string
}

export const RegisterPage = () => {
	const { hash } = useParams()
	const [accountCreated, setAccountCreated] = useState(false)
	const [accountCreateErrors, setAccountCreateErrors] =
		useState<RequestError[]>()

	const { control, setValue, getValues, watch, handleSubmit } =
		useForm<FormData>()

	const [captchaValue, setCaptchaValue] = useState<string | null>(null)
	const [captchaIsValid, setCaptchaIsValid] = useState<boolean>(true)

	const a = (value: string) => {
		setCaptchaValue(value)
	}

	const b = (value: boolean) => {
		setCaptchaIsValid(value)
	}

	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)

	const [popupVisible, setPopupVisible] = useState<boolean>(false)
	const [popupContent, setPopupContent] = useState<
		React.ReactNode | string | null
	>()

	const hidePopup = () => {
		setPopupVisible(false)
	}

	useEffect(() => {
		setLoading(true)
		const value = { hash: hash }

		RequestPost<any>(
			Uri.SignUpByHash,
			value,
			response => {
				setAccountCreated(true)
			},
			ex => {
				if (ex.response) {
					// client received an error response (5xx, 4xx)
					if (ex.response.status === 400) {
						//setAccountCreateErrors({ ...ex.response.data.errors })
						const errors: RequestError[] = ex.response.data.errors
						setAccountCreateErrors(errors)
					}
				} else if (ex.request) {
					//console.log(2)
					// client never received a response, or request never left
				} else {
					//console.log(3)
					// anything else
					//throw new Error(ex)
				}
			},
			() => {
				setLoading(false)
			},
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hash])

	const submitForm = (form: FormData) => {
		const value = {
			...form,
			captchaId: getCaptchaId(),
			captchaValue: captchaValue,
		}

		setLoading(true)

		RequestPost<any>(
			Uri.SignUp,
			value,
			response => {
				const captchaValid = response.data.captchaValid as boolean

				setCaptchaIsValid(captchaValid)

				if (captchaValid) {
					setPopupContent('Check you email for accept link.')
					setPopupVisible(true)
				}
			},
			ex => {
				if (ex.response) {
					// client received an error response (5xx, 4xx)
					if (ex.response.status === 400) {
						const errors: RequestError[] = ex.response.data.errors

						if (errors) {
							setPopupContent(
								<ul>
									{errors.map((value, index) => (
										<li key={index}>{value.message}</li>
									))}
								</ul>,
							)
						}
					} else {
						setPopupContent('Unprocessed error. Please, contact us.')
					}

					setPopupVisible(true)
				} else if (ex.request) {
					// client never received a response, or request never left
				} else {
					// anything else
					//throw new Error(ex)
				}

				//todo не работает и вроде не нужен после ререндера
				//reloadCaptchaImage()
			},
			() => {
				setLoading(false)
			},
		)
	}

	return loading ? (
		<LoadIndicator id="large-indicator" height={60} width={60} />
	) : hash ? (
		<Page title="Mail checking">
			{accountCreated ? (
				<div>
					Account created/confirmed successfully.{' '}
					<SignInLink>Sign In</SignInLink>
				</div>
			) : (
				<div>
					Account NOT created/confirmed. Reasons:
					{accountCreateErrors ? (
						<ul>
							{accountCreateErrors.map((value, index) => (
								<li key={index}>{value.message}</li>
							))}
						</ul>
					) : (
						'Unexpected error'
					)}
				</div>
			)}
		</Page>
	) : (
		<Page title="Create account">
			<Popup
				visible={popupVisible}
				onHiding={hidePopup}
				dragEnabled={false}
				closeOnOutsideClick={true}
				showCloseButton={true}
				showTitle={true}
				title="Information"
				container=".dx-viewport"
				width={300}
				height={280}
			>
				<ToolbarItem
					widget="dxButton"
					toolbar="bottom"
					location="after"
					options={{
						text: 'OK',
						onClick: hidePopup,
					}}
				/>
				{popupContent}
			</Popup>
			<form
				css={css`
					width: 100%;
					display: flex;
					flex-direction: column;
				`}
				onSubmit={handleSubmit(submitForm)}
			>
				<div
					className="dx-fieldset"
					css={css`
						margin: 0;
						width: 100%;
					`}
				>
					<div className="dx-field ">
						<div className="dx-field-value">
							<Controller
								control={control}
								name="email"
								render={({ field: { onChange, value } }) => (
									<TextBox
										mode="email"
										width="300"
										defaultValue={value}
										maxLength="50"
										valueChangeEvent="keyup"
										onValueChanged={e => onChange(e.value)}
									>
										<Validator>
											<RequiredRule message="Email or mobile phone field is required" />
											<EmailRule message="Email is invalid" />
										</Validator>
									</TextBox>
								)}
							/>
						</div>
						<div
							className="dx-field-label"
							css={css`
								text-align: right;
							`}
						>
							Email or mobile phone number
						</div>
					</div>
					<div className="dx-field ">
						<div className="dx-field-value">
							<Controller
								control={control}
								name="password"
								render={({ field: { onChange, value } }) => (
									<TextBox
										width="200"
										mode="password"
										defaultValue={value}
										maxLength="50"
										valueChangeEvent="keyup"
										onValueChanged={e => onChange(e.value)}
									>
										<Validator>
											<RequiredRule message="Password is required" />
										</Validator>
									</TextBox>
								)}
							/>
						</div>
						<div
							className="dx-field-label"
							css={css`
								text-align: right;
							`}
						>
							Password
						</div>
					</div>
					<div className="dx-field ">
						<div className="dx-field-value">
							<Controller
								control={control}
								name="passwordConfirm"
								render={({ field: { onChange, value } }) => (
									<TextBox
										width="200"
										mode="password"
										defaultValue={value}
										maxLength="50"
										valueChangeEvent="keyup"
										onValueChanged={e => onChange(e.value)}
									>
										<Validator>
											<RequiredRule message="Confirm Password is required" />
											<CompareRule
												message="Password and Confirm Password do not match"
												comparisonTarget={() => {
													return getValues('password')
												}}
											/>
										</Validator>
									</TextBox>
								)}
							/>
						</div>
						<div
							className="dx-field-label"
							css={css`
								text-align: right;
							`}
						>
							Confirm Password
						</div>
					</div>
					<div className="dx-field ">
						<div>
							<div className="some-page-wrapper">
								<div
									className="dx-field-value"
									css={css`
										display: flex;
										flex-direction: row;
										flex-wrap: wrap;
									`}
								>
									<BotDetectCaptcha isValid={captchaIsValid} a={a} b={b} />
								</div>
							</div>
						</div>
						<div
							className="dx-field-label"
							css={css`
								text-align: right;
							`}
						>
							Enter CAPTCHA
						</div>
					</div>
					<ValidationSummary id="summary" />
				</div>
				<p></p>
				<Button
					text="Create your account"
					type="success"
					stylingMode="contained"
					useSubmitBehavior={true}
				/>
			</form>
			<p
				css={css`
					display: flex;
					justify-content: center;
				`}
			>
				<span>Already have an account? &nbsp;</span>
				<SignInLink>Sign In</SignInLink>
			</p>
		</Page>
	)
}
