/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Page } from './Page'
import axios from 'axios'
import { Uri } from '../AppSettings'
import { useSearchParams } from 'react-router-dom'
import { LoadIndicator } from 'devextreme-react'
import { SapOrderStatus } from '../components/Data/Types'
import { mapSapOrderStatusResponse } from '../components/Data/Functions'

export const SapTransactionPage = () => {
	const [loading, setLoading] = useState(true)
	const [transaction, setTransaction] = useState<SapOrderStatus>()
	const [searchParams] = useSearchParams()

	const transactionId = searchParams.get('id') || searchParams.get('PAY_ORDER')

	useEffect(() => {
		const doGet = async (transactionId: number) => {
			axios.get(`${Uri.SapOrdersStatus}?id=${transactionId}`).then(response => {
				const transaction = mapSapOrderStatusResponse(response.data)

				setTransaction(transaction)

				setLoading(false)
			})
		}

		if (transactionId) {
			setLoading(true)
			doGet(Number(transactionId))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Page title="Transaction status">
			{loading || !transaction ? (
				<LoadIndicator id="large-indicator" height={60} width={60} />
			) : (
				<>
					<p>
						Transaction Id: {transaction.id}
						<br />
						Type: {transaction.typeName}
						<br />
						Login: {transaction.login}
						<br />
						Credits: {transaction.creditsAmount}
						<br />
						Money: {transaction.moneyAmount} {transaction.currencyCode}
						<br />
						Date: {transaction.dateCreate.toLocaleDateString()}{' '}
						{transaction.dateCreate.toLocaleTimeString()}
						<br />
						<b>Status: {transaction.statusName}</b>
					</p>
					{(transaction.statusId === 1 || transaction.statusId === 3) && (
						<h3>
							Attention! If transaction status won't change in 24 hours, please
							contact us
						</h3>
					)}
				</>
			)}
		</Page>
	)
}
