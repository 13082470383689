/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
	Button,
	DateBox,
	LoadIndicator,
	NumberBox,
	TagBox,
	Toast,
} from 'devextreme-react'
import Lookup, { DropDownOptions } from 'devextreme-react/lookup'
import { Position } from 'devextreme-react/toast'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import { useAuth } from 'oidc-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Uri } from '../../AppSettings'
import {
	DateFormat,
	RequestGet,
	RequestPost,
} from '../../components/Data/Functions'
import {
	AccountGameFull,
	City,
	Client,
	Device,
	Manager,
	Rent,
	RentState,
} from '../../components/Data/Types'
import {
	gettingAllClientsAction,
	gotAllClientsAction,
} from '../../store/ClientsReducer'
import {
	gettingAllDevicesAction,
	gotAllDevicesAction,
} from '../../store/DevicesReducer'
import {
	gettingAllAccountGamesAction,
	gotAllAccountGamesAction,
} from '../../store/AccountGamesReducer'
import { AppState } from '../../store/Store'
import { Page } from '../Page'
import {
	gettingAllCitiesAction,
	gotAllCitiesAction,
} from '../../store/CitiesReducer'
import {
	gettingAllManagersAction,
	gotAllManagersAction,
} from '../../store/ManagersReducer'

interface FormData {
	clientId: number
	managerId: number
	cityId: number
	deviceIds: number[]
	accountGameIds: number[]
	dateFrom: Date
	dateTo: Date
	price: number
	deposit: number
}

interface ToastConfig {
	type: 'custom' | 'error' | 'info' | 'success' | 'warning'
	isVisible: boolean | undefined
	message: string
}

export const RentCreatePage = () => {
	const authState = useAuth()
	const getUser = async () =>
		await authState.userManager.getUser().then(user => user)

	const dispatch = useDispatch()
	const clients = useSelector((state: AppState) => state.clients.all)
	const managers = useSelector((state: AppState) => state.managers.all)
	const cities = useSelector((state: AppState) => state.cities.all)
	const devices = useSelector((state: AppState) => state.devices.all)
	const accountGames = useSelector((state: AppState) => state.accountGames.all)

	useEffect(() => {
		const getDevicesData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllDevicesAction())
				const data = await RequestGet<Device[]>(
					Uri.Devices,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllDevicesAction(data))
			}
		}

		const getAccountGamesData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllAccountGamesAction())
				const data = await RequestGet<AccountGameFull[]>(
					`${Uri.AccountGames}?full=true`,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllAccountGamesAction(data))
			}
		}

		const getClientsData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllClientsAction())
				const data = await RequestGet<Client[]>(
					Uri.Clients,
					response => {
						response.data.forEach((elem: Client) => {
							if (elem.dateBirth) {
								elem.dateBirth = DateFormat(elem.dateBirth)
							}
						})

						return response.data
					},
					user.access_token,
				)
				dispatch(gotAllClientsAction(data))
			}
		}

		const getCitiesData = async () => {
			dispatch(gettingAllCitiesAction())
			const data = await RequestGet<City[]>(
				Uri.Cities,
				response => response.data,
			)
			dispatch(gotAllCitiesAction(data))
		}

		const getManagersData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllManagersAction())
				const data = await RequestGet<Manager[]>(
					Uri.Managers,
					response => {
						response.data.forEach((elem: Manager) => {
							if (elem.dateBirth) {
								elem.dateBirth = DateFormat(elem.dateBirth)
							}
						})

						return response.data
					},
					user.access_token,
				)
				dispatch(gotAllManagersAction(data))
			}
		}

		getDevicesData()
		getAccountGamesData()
		getClientsData()
		getCitiesData()
		getManagersData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [formSending, setFormSending] = useState(false)

	const dateToday = new Date()
	const dateTomorrow = dateToday.AddDays(1)

	const [rentCreateStatus, setRentCreateStatus] = useState<ToastConfig>({
		isVisible: false,
		type: 'info',
		message: '',
	})

	const onHiding = () => {
		setRentCreateStatus({
			...rentCreateStatus,
			isVisible: false,
		})
	}

	const { control, watch, handleSubmit } = useForm<FormData>({
		defaultValues: JSON.parse(localStorage.getItem('createRentForm') ?? '{}'),
	})

	const [dateFrom, dateTo, price, deposit] = watch([
		'dateFrom',
		'dateTo',
		'price',
		'deposit',
	]) // you can supply default value as second argument
	/**/

	//console.log(1, dateFrom, dateTo, price, deposit)

	const submitForm = async (form: FormData) => {
		const user = await getUser()

		form.dateFrom = DateFormat(form.dateFrom)
		form.dateTo = DateFormat(form.dateTo)

		if (user) {
			setFormSending(true)
			RequestPost<Rent>(
				Uri.Rents,
				form,
				() => {
					setRentCreateStatus({
						...rentCreateStatus,
						isVisible: true,
						type: 'success',
						message: 'Аренда создана',
					})
				},
				ex => {
					setRentCreateStatus({
						...rentCreateStatus,
						isVisible: true,
						type: 'error',
						message: 'Ошибка при создании аренды',
					})
				},
				() => {
					setFormSending(false)
				},
				user.access_token,
			)
		}
	}

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			localStorage.setItem('createRentForm', JSON.stringify(value))
		})

		return () => subscription.unsubscribe()
	}, [watch])

	const groupedAccountGamesStore = new DataSource({
		store: accountGames,
		key: 'id',
		group: 'accountName',
	})

	return (
		<Page title="Новая аренда">
			<Toast
				visible={rentCreateStatus.isVisible}
				message={rentCreateStatus.message}
				type={rentCreateStatus.type}
				onHiding={onHiding}
				displayTime={5000}
			>
				<Position my="center" at="center" of="#toastPosition" />
			</Toast>
			<form
				css={css`
					display: flex;
					flex-direction: column;
				`}
				onSubmit={handleSubmit(submitForm)}
			>
				<div
					css={css`
						display: flex;
						flex-direction: column;
						padding: 5px 0px;
					`}
				>
					<div
						css={css`
							display: flex;
							flex-direction: column;
							padding: 5px 0px;
						`}
					>
						<Controller
							control={control}
							name="managerId"
							render={({ field: { onChange, value } }) => (
								<Lookup
									dataSource={managers}
									valueExpr="id"
									displayExpr={(data: Manager) => {
										if (!data) return ''

										return [
											data.lastName,
											data.firstName,
											data.patronymicName,
										].join(' ')
									}}
									onValueChanged={e => onChange(e.value)}
									defaultValue={value}
									label="Менеджер"
									labelMode="floating"
								>
									<DropDownOptions
										closeOnOutsideClick={true}
										showTitle={false}
									/>
								</Lookup>
							)}
						/>
					</div>
					<div
						css={css`
							display: flex;
							flex-direction: column;
							padding: 5px 0px;
						`}
					>
						<Controller
							control={control}
							name="cityId"
							render={({ field: { onChange, value } }) => (
								<Lookup
									dataSource={cities}
									valueExpr="id"
									displayExpr="name"
									onValueChanged={e => onChange(e.value)}
									defaultValue={value}
									label="Город"
									labelMode="floating"
								>
									<DropDownOptions
										closeOnOutsideClick={true}
										showTitle={false}
									/>
								</Lookup>
							)}
						/>
					</div>
					<div
						css={css`
							display: flex;
							flex-direction: column;
							padding: 5px 0px;
						`}
					>
						<Controller
							control={control}
							name="clientId"
							render={({ field: { onChange, value } }) => (
								<Lookup
									dataSource={clients}
									valueExpr="id"
									displayExpr={(data: Client) => {
										if (!data) return ''

										return [
											data.lastName,
											data.firstName,
											data.patronymicName,
											'/',
											data.dateBirth
												? data.dateBirth.toLocaleDateString()
												: '----',
											'/ риск:',
											data.attentionLevel,
										].join(' ')
									}}
									onValueChanged={e => onChange(e.value)}
									defaultValue={value}
									label="Клиент"
									labelMode="floating"
								>
									<DropDownOptions
										closeOnOutsideClick={true}
										showTitle={false}
									/>
								</Lookup>
							)}
						/>
					</div>
					<div
						css={css`
							display: flex;
							flex-direction: column;
							padding: 5px 0px;
						`}
					>
						<Controller
							control={control}
							name="deviceIds"
							render={({ field: { onChange, value } }) => (
								<TagBox
									dataSource={devices}
									valueExpr="id"
									displayExpr={(obj: Device) =>
										`${obj.typeName} ${obj.serialNumber} - ${obj.description}`
									}
									onValueChanged={e => onChange(e.value)}
									defaultValue={value}
									label="Оборудование"
									labelMode="floating"
									searchEnabled={true}
									showSelectionControls={true}
									showClearButton={true}
								/>
							)}
						/>
					</div>
					<div
						css={css`
							display: flex;
							flex-direction: column;
							padding: 5px 0px;
						`}
					>
						<Controller
							control={control}
							name="accountGameIds"
							render={({ field: { onChange, value } }) => (
								<TagBox
									dataSource={groupedAccountGamesStore}
									grouped={true}
									valueExpr="id"
									displayExpr={(obj: AccountGameFull) =>
										`${obj.gameName} ${
											obj.gameNameRu ? `/ ${obj.gameNameRu}` : ``
										} (${obj.accountName})`
									}
									onValueChanged={e => onChange(e.value)}
									defaultValue={value}
									label="Игры"
									labelMode="floating"
									searchEnabled={true}
									showSelectionControls={true}
									showClearButton={true}
								/>
							)}
						/>
					</div>
					<div
						css={css`
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							justify-content: center;
							padding: 5px 0px;
						`}
					>
						{/*todo добавить проверку, что dateTo не может быть меньше dateFrom*/}
						<Controller
							control={control}
							name="dateFrom"
							render={({ field: { onChange, value } }) => (
								<DateBox
									openOnFieldClick={true}
									//applyValueMode="useButtons"
									defaultValue={value}
									min={dateToday}
									//max={this.now}
									onValueChanged={e => onChange(e.value)}
									label="Дата начала аренды"
									labelMode="floating"
									width="25%"
								/>
							)}
						/>
						<Controller
							control={control}
							name="dateTo"
							render={({ field: { onChange, value } }) => (
								<DateBox
									openOnFieldClick={true}
									//applyValueMode="useButtons"
									defaultValue={value}
									min={dateTomorrow}
									//max={this.now}
									onValueChanged={e => onChange(e.value)}
									label="Дата завершения аренды"
									labelMode="floating"
									width="25%"
								/>
							)}
						/>
					</div>
					<div
						css={css`
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							justify-content: center;
							padding: 5px 0px;
						`}
					>
						<Controller
							control={control}
							name="price"
							render={({ field: { onChange, value } }) => (
								<NumberBox
									defaultValue={value}
									min={0}
									max={100000}
									step={100}
									showSpinButtons={true}
									valueChangeEvent="keyup"
									onValueChanged={e => onChange(e.value)}
									label="Стоимость"
									labelMode="floating"
									width="25%"
								/>
							)}
						/>
						<Controller
							control={control}
							name="deposit"
							render={({ field: { onChange, value } }) => (
								<NumberBox
									defaultValue={value}
									min={0}
									max={100000}
									step={500}
									showSpinButtons={true}
									valueChangeEvent="keyup"
									onValueChanged={e => onChange(e.value)}
									label="Залог"
									labelMode="floating"
									width="25%"
								/>
							)}
						/>
					</div>
					{dateFrom && dateTo && price && deposit ? (
						<div
							css={css`
								display: flex;
								flex-direction: row;
								flex-wrap: wrap;
								justify-content: center;
								padding: 5px 0px;
							`}
						>
							Аренда на {DateFormat(dateTo).DiffDays(DateFormat(dateFrom))} дн.,
							в период с {DateFormat(dateFrom).toLocaleDateString()} по{' '}
							{DateFormat(dateTo).toLocaleDateString()}. Стоимость {price} руб.,
							залог {deposit} руб.
						</div>
					) : (
						''
					)}
					<div
						css={css`
							display: flex;
							flex-direction: column;
							padding: 5px 0px;
						`}
					>
						{formSending ? (
							<LoadIndicator id="large-indicator" height={60} width={60} />
						) : (
							<Button
								text="Создать"
								//todo можно как-то подключить иконку, но не срабатывает чтобы как в примере со страницы https://js.devexpress.com/Demos/WidgetsGallery/Demo/Button/Icons/React/Light/
								//icon="fa fa-envelope-o"
								type="default"
								stylingMode="contained"
								useSubmitBehavior={true}
							/>
						)}
					</div>
					{/* todo для примера со сбросом строки фильтра после клика по галочке
					<TagBox
						dataSource={groupedAccountGamesStore}
						grouped={true}
						valueExpr="gameId"
						displayExpr={(obj: AccountGameFull) =>
								`${obj.gameName} ${
									obj.gameNameRu ? `/ ${obj.gameNameRu}` : ``
								} (${obj.accountName})`
							}
						label="Игры"
						labelMode="floating"
						searchEnabled={true}
						showSelectionControls={true}
						showClearButton={true}
					/>
					*/}
				</div>
			</form>
			<div id="toastPosition"></div>
		</Page>
	)
}
