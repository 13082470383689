import { Client } from '../components/Data/Types'

export interface ClientsState {
	readonly loading: boolean
	readonly all: Client[]
}

export const GETTINGALLCLIENTS = 'GettingAllClients'
export const GOTGALLCLIENTS = 'GotAllClients'

export const gettingAllClientsAction = () =>
	({ type: GETTINGALLCLIENTS } as const)
export const gotAllClientsAction = (data: Client[]) =>
	({ type: GOTGALLCLIENTS, clients: data } as const)

type ClientsActions =
	| ReturnType<typeof gettingAllClientsAction>
	| ReturnType<typeof gotAllClientsAction>

const initialClientsState: ClientsState = {
	loading: false,
	all: [],
}

export const clientsReducer = (
	state = initialClientsState,
	action: ClientsActions,
) => {
	switch (action.type) {
		case GETTINGALLCLIENTS: {
			return { ...state, loading: true }
		}
		case GOTGALLCLIENTS: {
			return { ...state, all: action.clients, loading: false }
		}
		default:
			return state
	}
}
