/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import { ExternalLink } from 'react-external-link'

interface Props {
	to: string
	children?: React.ReactNode
}

export const Email = ({ to, children = to }: Props) => (
	<ExternalLink
		css={css`
			cursor: pointer;
			background: url('/email.png') no-repeat left center;
			//margin: 8px 0 8px 20px;
			padding: 0 0 0 24px;
			color: #666;
			text-decoration: underline;
			font-size: 12px;
		`}
		href={'mailto:' + to}
	>
		{children}
	</ExternalLink>
)
