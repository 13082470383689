import React, { useEffect } from 'react'
import DataGrid, {
	Column,
	Editing,
	Lookup,
	Paging,
	SearchPanel,
} from 'devextreme-react/data-grid'
import TabPanel, { Item } from 'devextreme-react/tab-panel'
import { Form } from 'devextreme-react'
import CustomStore from 'devextreme/data/custom_store'
import { useAuth } from 'oidc-react'
import { Uri } from '../AppSettings'
import { Account, AccountGame, AccountGameFull, Game } from './Data/Types'
import DataSource from 'devextreme/data/data_source'
import { RequestGet, RequestPatch, RequestPost } from './Data/Functions'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../store/Store'
import {
	gettingAllAccountGamesAction,
	gotAllAccountGamesAction,
} from '../store/AccountGamesReducer'
import { gettingAllGamesAction, gotAllGamesAction } from '../store/GamesReducer'

interface Props {
	data: { data: Account }
}

export const AccountDetails = ({ data: { data: account } }: Props) => {
	//todo как убрать это, чтобы не писать везде где используется
	const authState = useAuth()
	const getUser = async () =>
		await authState.userManager.getUser().then(user => user)

	const dispatch = useDispatch()
	const accountGames = useSelector((state: AppState) => state.accountGames.all)
	const games = useSelector((state: AppState) => state.games.all)

	useEffect(() => {
		const getAccountGamesData = async () => {
			const user = await getUser()

			if (user) {
				dispatch(gettingAllAccountGamesAction())
				const data = await RequestGet<AccountGameFull[]>(
					`${Uri.AccountGames}?full=true`,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllAccountGamesAction(data))
			}
		}

		const getGamesData = async () => {
			dispatch(gettingAllGamesAction())
			const data = await RequestGet<Game[]>(
				Uri.Games,
				response => response.data,
			)
			dispatch(gotAllGamesAction(data))
		}

		getGamesData()

		getAccountGamesData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	//todo
	// + событие изменения поля GameId
	// структуризация и деструктуризация объекта
	// псевдонимы в деструктуризации
	// js microtasks, macrotasks
	// const var let
	//const ololo = { ...accountGames(), ...games() }
	/*
	var arr3 = arr.map(function(obj,i){
		var arr2match = arr2.filter( function(x){
			return x.id == obj.id;
		})[0];
		obj.qty = arr2match.qty;
		return obj;
	});
	*/

	//тут хорошее описание работы с редактором
	//https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/React/Light/
	const accountGamesStore = new CustomStore({
		key: 'id',
		loadMode: 'raw',
		load: () => accountGames,
		insert: async value => {
			const user = await getUser()

			if (user) {
				return RequestPost<AccountGame>(
					Uri.AccountGames,
					{ ...value, accountId: account.id },
					undefined,
					undefined,
					undefined,
					user.access_token,
				)
			}
		},
		update: async (key, value) => {
			const user = await getUser()

			if (user) {
				return RequestPatch<AccountGame>(
					`${Uri.AccountGames}/${key}`,
					response => response.data,
					{ ...value, accountId: account.id },
					user.access_token,
				)
			}
		},
		remove: async key => {},
	})

	const getAccountGames = (accountId: number) => {
		return new DataSource({
			store: accountGamesStore,
			filter: ['accountId', '=', accountId],
		})
	}

	return (
		<TabPanel>
			<Item
				title="Игры"
				render={() => {
					return (
						<Form labelLocation="top" className="form-container">
							<Item
								render={() => {
									return (
										<DataGrid
											dataSource={getAccountGames(account.id)}
											showBorders={false}
										>
											<Editing
												mode="form"
												allowUpdating={true}
												allowAdding={true}
												//allowDeleting={true}
												useIcons={true}
												newRowPosition="first"
												refreshMode="reshape"
											/>
											<SearchPanel visible={true} />
											<Paging defaultPageSize={25} />
											<Column
												dataField="gameName"
												dataType="string"
												caption="Название, English"
												formItem={{ visible: false }}
											/>
											<Column
												dataField="gameNameRu"
												dataType="string"
												caption="Название, Русский"
												formItem={{ visible: false }}
											/>
											<Column dataField="gameId" caption="Название">
												{/*todo 
												// как сделать по всей ширине?
												// как заставить обновляться поля gameName и gameNameRu?
												*/}
												<Lookup
													dataSource={games}
													valueExpr="id"
													displayExpr={(obj: Game) =>
														`${obj.name} ${obj.nameRu ? `/ ${obj.nameRu}` : ``}`
													}
												/>
											</Column>
										</DataGrid>
									)
								}}
							/>
						</Form>
					)
				}}
			/>
		</TabPanel>
	)
}
