import { RentState } from '../components/Data/Types'

export interface RentStatesState {
	readonly loading: boolean
	readonly all: RentState[]
}

export const GETTINGALLRENTSTATES = 'GettingAllRentStates'
export const GOTGALLRENTSTATES = 'GotAllRentStates'

export const gettingAllRentStatesAction = () =>
	({ type: GETTINGALLRENTSTATES } as const)
export const gotAllRentStatesAction = (data: RentState[]) =>
	({ type: GOTGALLRENTSTATES, rentStates: data } as const)

type RentStatesActions =
	| ReturnType<typeof gettingAllRentStatesAction>
	| ReturnType<typeof gotAllRentStatesAction>

const initialRentStatesState: RentStatesState = {
	loading: false,
	all: [],
}

export const rentsStatesReducer = (
	state = initialRentStatesState,
	action: RentStatesActions,
) => {
	switch (action.type) {
		case GETTINGALLRENTSTATES: {
			return { ...state, loading: true }
		}
		case GOTGALLRENTSTATES: {
			return { ...state, all: action.rentStates, loading: false }
		}
		default:
			return state
	}
}
