import { DataGrid, LoadIndicator } from 'devextreme-react'
import {
	Column,
	Editing,
	FormItem,
	Lookup,
	Paging,
	Scrolling,
	SearchPanel,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import { useAuth } from 'oidc-react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Uri } from '../../AppSettings'
import {
	DateFormat,
	RequestGet,
	RequestPatch,
	RequestPost,
} from '../../components/Data/Functions'
import { City, Client } from '../../components/Data/Types'
import {
	gettingAllCitiesAction,
	gotAllCitiesAction,
} from '../../store/CitiesReducer'
import {
	gettingAllClientsAction,
	gotAllClientsAction,
} from '../../store/ClientsReducer'
import { AppState } from '../../store/Store'
import { Page } from '../Page'

export const ClientsPage = () => {
	const authState = useAuth()
	const getUser = async () =>
		await authState.userManager.getUser().then(user => user)
	const dispatch = useDispatch()
	const clients = useSelector((state: AppState) => state.clients.all)
	const cities = useSelector((state: AppState) => state.cities.all)
	//const clientsLoading = useSelector((state: AppState) => state.clients.loading)

	useEffect(() => {
		const getClientsData = async () => {
			const user = await getUser()
			if (user) {
				dispatch(gettingAllClientsAction())
				const data = await RequestGet<Client[]>(
					Uri.Clients,
					response => {
						response.data.forEach((elem: Client) => {
							if (elem.dateBirth) {
								elem.dateBirth = DateFormat(elem.dateBirth)
							}
						})

						return response.data
					},
					user.access_token,
				)
				dispatch(gotAllClientsAction(data))
			}
		}

		const getCitiesData = async () => {
			dispatch(gettingAllCitiesAction())
			const data = await RequestGet<City[]>(
				Uri.Cities,
				response => response.data,
			)
			dispatch(gotAllCitiesAction(data))
		}

		getClientsData()
		getCitiesData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onInitNewRow = (e: any) => {
		//галочка по умолчанию активна
		e.data.isActive = true
	}

	//тут хорошее описание работы с редактором
	//https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/React/Light/
	const clientsStore = new CustomStore({
		key: 'id',
		loadMode: 'raw',
		load: () => clients,
		insert: async value => {
			const user = await getUser()

			if (value.dateBirth) {
				value.dateBirth = DateFormat(value.dateBirth)
			}

			if (user) {
				return RequestPost<Client>(
					Uri.Clients,
					value,
					undefined,
					undefined,
					undefined,
					user.access_token,
				)
			}
		},
		update: async (key, value) => {
			const user = await getUser()
			if (user) {
				return RequestPatch<Client>(
					`${Uri.Clients}/${key}`,
					response => response.data,
					value,
					user.access_token,
				)
			}
		},
		remove: async key => {},
	})

	return (
		/*clientsLoading ? ( <LoadIndicator id="large-indicator" height={60} width={60} /> ) : */
		<Page title="Клиенты">
			<DataGrid
				dataSource={clientsStore}
				allowColumnReordering={true}
				allowColumnResizing={true}
				columnResizingMode="widget"
				hoverStateEnabled={true}
				onInitNewRow={onInitNewRow}
			>
				<Scrolling mode="standard" />
				<Paging enabled={false} />
				<SearchPanel visible={true} />
				<Editing
					mode="form"
					allowUpdating={true}
					allowAdding={true}
					//allowDeleting={true}
					useIcons={true}
					newRowPosition="first"
					refreshMode="reshape"
				/>
				<Column
					dataField="id"
					caption="#"
					width={50}
					allowEditing={false}
					defaultSortIndex={0}
					defaultSortOrder="desc"
				/>
				<Column
					dataField="lastName"
					dataType="string"
					caption="Фамилия"
					visible={false}
				/>
				<Column
					dataField="firstName"
					dataType="string"
					caption="Имя"
					visible={false}
				/>
				<Column
					dataField="patronymicName"
					dataType="string"
					caption="Отчество"
					visible={false}
				/>
				<Column dataField="cityId" caption="Город">
					<Lookup dataSource={cities} valueExpr="id" displayExpr="name" />
				</Column>
				<Column
					dataType="string"
					caption="ФИО"
					calculateCellValue={(data: Client) => {
						return [data.lastName, data.firstName, data.patronymicName].join(
							' ',
						)
					}}
					formItem={{ visible: false }}
					allowSorting={true}
				/>
				<Column
					dataField="phone"
					dataType="string"
					caption="Телефон"
					width={150}
				>
					<FormItem
						colSpan={1}
						editorType="dxTextBox"
						editorOptions={{
							mask: '+0 (000) 000-0000',
							maskChar: ' ',
							maskInvalidMessage: '',
							useMaskedValue: false,
							attr: { autocomplete: 'nope' },
						}}
					/>
				</Column>
				<Column
					dataField="attentionLevel"
					dataType="number"
					caption="Риск"
					width={75}
				/>
				<Column
					dataField="email"
					dataType="string"
					caption="Email"
					visible={false}
				/>
				<Column
					dataField="dateBirth"
					dataType="date"
					caption="Дата рождения"
					visible={false}
				/>
				<Column caption="Паспорт" visible={false}>
					<Column dataField="passportS" dataType="string" caption="Серия" />
					<Column dataField="passportN" dataType="string" caption="Номер" />
					<Column dataField="passportI" dataType="string" caption="Выдан">
						<FormItem
							colSpan={2}
							editorType="dxTextArea"
							//editorOptions={{ height: 35 }}
						/>
					</Column>
				</Column>
				<Column
					dataField="driverLicense"
					dataType="string"
					caption="Вод. уд."
					visible={false}
				>
					<FormItem
						colSpan={2}
						editorType="dxTextArea"
						//editorOptions={{ height: 35 }}
					/>
				</Column>
			</DataGrid>
		</Page>
	)
}
