import { Store, createStore, combineReducers } from 'redux'
import { accountsReducer, AccountsState } from './AccountsReducer'
import { clientsReducer, ClientsState } from './ClientsReducer'
import { devicesReducer, DevicesState } from './DevicesReducer'
import { accountGamesReducer, AccountGamesState } from './AccountGamesReducer'
import { gamesReducer, GamesState } from './GamesReducer'
import { rentsReducer, RentsState } from './RentsReducer'
import { rentsStatesReducer, RentStatesState } from './RentStatesReducer'
import { rentDevicesReducer, RentDevicesState } from './RentDevicesReducer'
import {
	rentAccountGamesReducer,
	RentAccountGamesState,
} from './RentAccountGamesReducer'
import { citiesReducer, CitiesState } from './CitiesReducer'
import { managersReducer, ManagersState } from './ManagersReducer'

export interface AppState {
	readonly games: GamesState
	readonly clients: ClientsState
	readonly rents: RentsState
	readonly rentStates: RentStatesState
	readonly accounts: AccountsState
	readonly devices: DevicesState
	readonly accountGames: AccountGamesState
	readonly rentDevices: RentDevicesState
	readonly rentAccountGames: RentAccountGamesState
	readonly cities: CitiesState
	readonly managers: ManagersState
}

const rootReducer = combineReducers<AppState>({
	games: gamesReducer,
	clients: clientsReducer,
	rents: rentsReducer,
	rentStates: rentsStatesReducer,
	accounts: accountsReducer,
	devices: devicesReducer,
	accountGames: accountGamesReducer,
	rentDevices: rentDevicesReducer,
	rentAccountGames: rentAccountGamesReducer,
	cities: citiesReducer,
	managers: managersReducer,
})

export function configureStore(): Store<AppState> {
	const store = createStore(rootReducer, undefined)
	return store
}
