import './custom.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import React from 'react'
import { Provider } from 'react-redux'
import { configureStore } from './store/Store'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Layout } from './components/Layout'
import { FaqPage } from './pages/FaqPage'
import { TermsPage } from './pages/TermsPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { SapPage } from './pages/SapPage'
import { SellPage } from './pages/SellPage'
import { BuyPage } from './pages/BuyPage'
import { AuthorizedPage } from './pages/AuthorizedPage'
import { SignInPage } from './pages/SignInPage'
import { AccountPage } from './pages/AccountPage'
import { RegisterPage } from './pages/RegisterPage'
import { PasswordResetPage } from './pages/PasswordResetPage'
import { PasswordResetByHashPage } from './pages/PasswordResetByHashPage'
import { SapTransactionPage } from './pages/SapTransactionPage'
import { locale } from 'devextreme/localization'
import { AuthProvider } from './components/AuthProvider'
import { GamesPage } from './pages/ps/GamesPage'
import { DevicesPage } from './pages/ps/DevicesPage'
import { ClientsPage } from './pages/ps/ClientsPage'
import { AccountsPage } from './pages/ps/AccountsPage'
import { RentCreatePage } from './pages/ps/RentCreatePage'
import { RentsPage } from './pages/ps/RentsPage'
import { AuthorizedArea } from './components/AuthorizedArea'
import { IdentityRoles } from './AppSettings'

const store = configureStore()
const App = () => {
	//локализация. Добавил, чтобы между тысячами были пробелы вместо запятой в NumberBox
	locale(navigator.language)

	return (
		<Provider store={store}>
			<BrowserRouter>
				<AuthProvider>
					<Layout>
						<Routes>
							<Route path="" element={<BuyPage />} />
							<Route path="buy" element={<BuyPage />} />
							<Route path="sell" element={<SellPage />} />
							<Route path="transactions" element={<SapTransactionPage />} />
							<Route path="terms" element={<TermsPage />} />
							<Route path="faq" element={<FaqPage />} />
							<Route path="sap" element={<SapPage />} />
							<Route path="signin" element={<SignInPage />}>
								<Route path=":signAction" element={<SignInPage />} />
							</Route>
							<Route path="signup" element={<RegisterPage />}>
								<Route path=":hash" element={<RegisterPage />} />
							</Route>
							<Route
								path="passwordreset/:hash"
								element={<PasswordResetByHashPage />}
							/>
							<Route path="passwordreset" element={<PasswordResetPage />} />
							<Route
								path="account"
								element={
									<AuthorizedPage>
										<AccountPage />
									</AuthorizedPage>
								}
							/>
							<Route
								path="psdevices"
								element={
									<AuthorizedPage>
										<AuthorizedArea role={IdentityRoles.PS_MANAGER}>
											<DevicesPage />
										</AuthorizedArea>
									</AuthorizedPage>
								}
							/>
							<Route
								path="psrentcreate"
								element={
									<AuthorizedPage>
										<AuthorizedArea role={IdentityRoles.PS_MANAGER}>
											<RentCreatePage />
										</AuthorizedArea>
									</AuthorizedPage>
								}
							/>
							<Route
								path="psaccounts"
								element={
									<AuthorizedPage>
										<AuthorizedArea role={IdentityRoles.PS_MANAGER}>
											<AccountsPage />
										</AuthorizedArea>
									</AuthorizedPage>
								}
							/>
							<Route
								path="psgames"
								element={
									<AuthorizedPage>
										<AuthorizedArea role={IdentityRoles.PS_MANAGER}>
											<GamesPage />
										</AuthorizedArea>
									</AuthorizedPage>
								}
							/>
							<Route
								path="psclients"
								element={
									<AuthorizedPage>
										<AuthorizedArea role={IdentityRoles.PS_MANAGER}>
											<ClientsPage />
										</AuthorizedArea>
									</AuthorizedPage>
								}
							/>
							<Route
								path="psrents"
								element={
									<AuthorizedPage>
										<AuthorizedArea role={IdentityRoles.PS_MANAGER}>
											<RentsPage />
										</AuthorizedArea>
									</AuthorizedPage>
								}
							/>
							{/*
				<Route path="transactions" element={<TransactionsPage />} />
				<Route
					path="admin"
					element={
						<React.Suspense
							fallback={
								<div
									css={css`
										margin-top: 100px;
										text-align: center;
									`}
								>
									Loading...
								</div>
							}
						>
							<AuthorizedPage>
								<AdminPage />
							</AuthorizedPage>
						</React.Suspense>
					}
				/>
				<Route path="accounts" element={<AccountsPage />} />
				<Route path="_buy2" element={<Deal typeName="buy" />} />

				<Route
					path="signin"
					element={<Page title="In develop">Release in March 2022</Page>}
				/>
				*/}

							<Route path="*" element={<NotFoundPage />} />
						</Routes>
					</Layout>
				</AuthProvider>
			</BrowserRouter>
		</Provider>
	)
}

export default App
