/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Hr } from '../components/Styles'
import { Email } from '../components/Email'
import { Page } from './Page'

export const SapPage = () => (
	<Page title="Systems of Active Promotion and online earn">
		<p>
			WEWEC.COM - it is Credits Exchange of SAP, which works only with good
			recommended and consistently implement earns systems.As exchange we work
			throught 2009 year.
		</p>
		<p>
			We know good this branch and in this article show our opinion and
			recommendations.Thanks to our customers and their wishes we make aim to
			develop big service, which works in online surfing and online marketing
			branch.We decided to write an article that will help users and
			advertisers, as well as a potential customers, to learn about online
			surfing and online marketing
		</p>
		<p>
			Sometimes this article will change, get wider and increase thanks to
			WEWEC.COM customers and System of Active Promotion administration.We hope
			that we will be able to create an interesting and useful booklet.
		</p>
		<Hr />
		<h1
			css={css`
				color: #000;
				font-size: 24px;
				font-weight: normal;
			`}
		>
			What is a System of Active Promotion?
		</h1>
		<p>
			There are a lot of ways to earn.Each of them takes different amount of
			time, mental, physical and financial costs.As a step towards 'easy money'
			can be considered the Internet.At certain extent it is work on the
			Internet.
		</p>
		<p>
			There are many websites that offer to advertisers thousands of site
			visitors and readers of advertising messages - such sites call 'System of
			Active Promotion', abbreviated SAP.These systems solve two problems -
			driving traffic to advertisers websites and reward users for their visit.
		</p>
		<p>
			If you are reading this article, it means you have interest to Systems of
			Active Promotion and earning online and you want to try yourself in this
			branch, or are already a user of one or more SAP.
		</p>
		<Hr />
		<h1
			css={css`
				color: #000;
				font-size: 24px;
				font-weight: normal;
			`}
		>
			Additional earn online
		</h1>
		<p>
			For the start let's talk about earnings at Systems of Active Advertising,
			online surfing, SAP points, referals, Email letters, users and
			advertisers, Exchange of SAP credits. Internet surfing and everything
			associated with it - is a very common type of income on the internet.
			Internet surfing - it's a general term that for a surfer(SAP user) means
			viewing advertising offers of advertisers.Whether it's performing tasks,
			reading Emails or just surfing - all it is a view of promotional offer. An
			advertiser pays for each user SAP and SAP fulfills its obligations to the
			advertiser - ends the job, mails and links to their users. So, a System of
			Active Promotion acts as an intermediary in the transaction between an
			advertiser and an user - SAP is involved in points payment for user
			performing of tasks.
		</p>
		<p>
			It so happened that at the correct construction of scheme earnings, System
			of Active Advertising brings good returns to its owner.Because of this, a
			network has a lot of different SAP, offering advertisers to buy credits
			SAP and promote your website or business proposal in their system.These
			SAP exhibit their rules for surfers, tariffs, etc.
		</p>
		<p>
			<b>We offer you to become a surfer</b> and register in one of Systems of
			Active Promotion from the list below.Earned points and other units(which
			emits SAP) you can sell or buy at our exchange, with the money / points
			instantly credited to your account, and upon completion of the
			transaction, you can not worry about the security of your account SAP - we
			keep the Federal Law of the Russian Federation on July 27, 2006 № 152-FZ
			'O personalnih dannih' and do not store information about your passwords,
			tokens, etc.
		</p>
		<h1
			css={css`
				color: #000;
				font-size: 24px;
				font-weight: normal;
			`}
		>
			Additional earn online
		</h1>
		<p>
			Here we list most popular Systems of Active Promotion.If a system is on
			the list - then it is better than others for many reasons.At first it is a
			huge number of registered users, experience at the market of internet
			surfing and competent support service.Most of these systems has own forum,
			where you can discuss questions with other users.
		</p>
		<p>
			Of cause, these list is not full.If you use or have own of good System of
			Active Promotion - write to us, please (
			<Email to="support@onestatus.org" />) and we consider a possibility of its
			connection to our Exchange of SAP points.
		</p>
		<Hr />
		<p>
			<i>
				Article belongs to owners of the project WEWEC.COM, use at other
				information sources is allow only with permission from administration of
				the project (
				<Email to="support@onestatus.org" />
				).
			</i>
		</p>
	</Page>
)
