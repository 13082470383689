import { Rent } from '../components/Data/Types'

export interface RentsState {
	readonly loading: boolean
	readonly all: Rent[]
}

export const GETTINGALLRENTS = 'GettingAllRents'
export const GOTGALLRENTS = 'GotAllRents'

export const gettingAllRentsAction = () => ({ type: GETTINGALLRENTS } as const)
export const gotAllRentsAction = (data: Rent[]) =>
	({ type: GOTGALLRENTS, rents: data } as const)

type RentsActions =
	| ReturnType<typeof gettingAllRentsAction>
	| ReturnType<typeof gotAllRentsAction>

const initialRentsState: RentsState = {
	loading: false,
	all: [],
}

export const rentsReducer = (
	state = initialRentsState,
	action: RentsActions,
) => {
	switch (action.type) {
		case GETTINGALLRENTS: {
			return { ...state, loading: true }
		}
		case GOTGALLRENTS: {
			return { ...state, all: action.rents, loading: false }
		}
		default:
			return state
	}
}
