/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { Uri } from '../AppSettings'
import {
	Button,
	LoadIndicator,
	TextBox,
	ValidationSummary,
	Validator,
} from 'devextreme-react'
import { RequiredRule, EmailRule } from 'devextreme-react/validator'
import { Page } from './Page'
import { HashLink } from 'react-router-hash-link'
import { BotDetectCaptcha, getCaptchaId } from '../components/BotDetectCaptcha'
import { Controller, useForm } from 'react-hook-form'
import Popup, { ToolbarItem } from 'devextreme-react/popup'
import { RequestError } from '../components/Data/Types'
import { YetAnotherLink } from '../components/Styles'
import { RequestPost } from '../components/Data/Functions'

interface FormData {
	email: string
}

export const PasswordResetPage = () => {
	const { control, setValue, getValues, watch, handleSubmit } =
		useForm<FormData>({
			defaultValues: JSON.parse(
				localStorage.getItem('passwordResetRequestForm') ?? '{}',
			),
		})

	const [captchaIsValid, setCaptchaIsValid] = useState(true)
	const [captchaValue, setCaptchaValue] = useState<string | null>(null)

	const [loading, setLoading] = useState(false)

	const [popupVisible, setPopupVisible] = useState<boolean>(false)
	const [popupContent, setPopupContent] = useState<
		React.ReactNode | string | null
	>()

	const hidePopup = () => {
		setPopupVisible(false)
	}

	const [login, setLogin] = useState<string>()

	const a = (value: string) => {
		setCaptchaValue(value)
	}

	const b = (value: boolean) => {
		setCaptchaIsValid(value)
	}

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			localStorage.setItem('passwordResetRequestForm', JSON.stringify(value))
		})

		return () => subscription.unsubscribe()
	}, [watch, login])

	const submitForm = (form: FormData) => {
		let value = {
			email: form.email,
			captchaId: getCaptchaId(),
			captchaValue: captchaValue,
		}

		setLoading(true)

		RequestPost<any>(
			Uri.PasswordReset,
			value,
			response => {
				const captchaValid = response.data.captchaValid as boolean

				setCaptchaIsValid(captchaValid)

				if (captchaValid) {
					setPopupContent('Check you email for next instructions.')
					setPopupVisible(true)
				}
			},
			ex => {
				if (ex.response) {
					// client received an error response (5xx, 4xx)
					if (ex.response.status === 400) {
						const errors: RequestError[] = ex.response.data.errors

						if (errors) {
							setPopupContent(
								<ul>
									{errors.map((value, index) => (
										<li key={index}>{value.message}</li>
									))}
								</ul>,
							)
						}
					} else {
						setPopupContent('Unprocessed error. Please, contact us.')
					}

					setPopupVisible(true)
				} else if (ex.request) {
					// client never received a response, or request never left
				} else {
					// anything else
					//throw new Error(ex)
				}

				//todo не работает и вроде не нужен после ререндера
				//reloadCaptchaImage()
			},
			() => {
				setLoading(false)
			},
		)
	}

	return loading ? (
		<LoadIndicator id="large-indicator" height={60} width={60} />
	) : (
		<Page title="Password reset assistance">
			<Popup
				visible={popupVisible}
				onHiding={hidePopup}
				dragEnabled={false}
				closeOnOutsideClick={true}
				showCloseButton={true}
				showTitle={true}
				title="Information"
				container=".dx-viewport"
				width={300}
				height={280}
			>
				<ToolbarItem
					widget="dxButton"
					toolbar="bottom"
					location="after"
					options={{
						text: 'OK',
						onClick: hidePopup,
					}}
				/>
				{popupContent}
			</Popup>
			<p>
				Enter the email address or mobile phone number associated with your
				account.
			</p>
			<form
				css={css`
					width: 100%;
					display: flex;
					flex-direction: column;
				`}
				onSubmit={handleSubmit(submitForm)}
			>
				<div
					className="dx-fieldset"
					css={css`
						margin: 0;
						width: 100%;
					`}
				>
					<div className="dx-field ">
						<div className="dx-field-value">
							{/* 
								<TextBox
								width="300"
								placeholder="Enter login"
								defaultValue={login}
								name="login"
								maxLength="50"
								onValueChanged={(e: any) => setLogin(e.value)}
							>
								<Validator>
									<RequiredRule message="Email or mobile phone field is required" />
								</Validator>
							</TextBox>
							*/}
							<Controller
								control={control}
								name="email"
								render={({ field: { onChange, value } }) => (
									<TextBox
										placeholder="Your email"
										mode="email"
										showClearButton={true}
										value={value}
										valueChangeEvent="keyup"
										onValueChanged={e => onChange(e.value)}
									>
										{/*валидатор взял отсюда https://js.devexpress.com/Demos/WidgetsGallery/Demo/Validation/Overview/React/Light/*/}
										<Validator>
											<RequiredRule message="Email is required" />
											<EmailRule message="Email is invalid" />
											{/* <AsyncRule message="Email is already registered" validationCallback={asyncValidation} /> */}
										</Validator>
									</TextBox>
								)}
							/>
						</div>
						<div
							className="dx-field-label"
							css={css`
								text-align: right;
							`}
						>
							Email or mobile phone number
						</div>
					</div>
					<div className="dx-field ">
						<div>
							<div className="some-page-wrapper">
								<div
									className="dx-field-value"
									css={css`
										display: flex;
										flex-direction: row;
										flex-wrap: wrap;
									`}
								>
									<BotDetectCaptcha isValid={captchaIsValid} a={a} b={b} />
								</div>
							</div>
						</div>
						<div
							className="dx-field-label"
							css={css`
								text-align: right;
							`}
						>
							Enter CAPTCHA
						</div>
					</div>
					<ValidationSummary id="summary" />
				</div>
				<p></p>
				<Button
					text="Continue"
					type="success"
					stylingMode="contained"
					useSubmitBehavior={true}
				/>
			</form>
			<h4>Has your email or mobile number changed?</h4>
			<p>
				If you no longer use the email address associated with your account, you
				may contact{' '}
				<HashLink css={YetAnotherLink} smooth to="#contacts">
					Customer Service
				</HashLink>{' '}
				for help restoring access to your account.
			</p>
		</Page>
	)
}
