/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import { Page } from './Page'

export const TermsPage = () => (
	<Page title="Service agreement">
		<ol>
			<li>
				<h3>General Provisions</h3>
			</li>
			<ol>
				<li>
					This Agreement governs the procedure and conditions for the provision
					of Services by service "WEWEC.COM";
				</li>
				<li>
					"WEWEC.COM" (hereinafter SERVICE) - a software service, located on the
					Internet at www.wewec.com;
				</li>
				<li>
					CLIENT - a person who wishes to use the services of the SERVICE;
				</li>
				<li>
					System of Active Advertising(the SAP) - a software system that allows
					advertisers to attract visitors to their website;
				</li>
				<li>
					Payment System(hereafter PS) - a set of services, provided by PS to
					clients of the PS funds transfer without bank accounts and the
					provision of electronic means of payment, description and other
					information which is available at the PS website;
				</li>
				<li>Credits, points - calculation units of SAP, hereafter CREDITS.</li>
				<li>
					Customer's use of services provided by the SERVICE, implies automatic
					acceptance of this Agreement in its entirety.
				</li>
			</ol>
			<li>
				<h3>Scope of agreement</h3>
			</li>
			<ol>
				<li>
					SERVICE with the SAP(paragraph 4) and PS(paragraph 5) provides
					services to the CLIENT(paragraph 6), according to the
					regulations(paragraph 7);
				</li>
				<li>
					CLIENT uses the services of the SERVICE and pay for them in accordance
					with the terms of this Agreement.
				</li>
			</ol>
			<li>
				<h3>The rights and commitments of the parties</h3>
			</li>
			<ol>
				<li>
					<h3>SERVICE shall</h3>
					<ol>
						<li>
							Provide services to CLIENT for the buy/sell of SAP CREDITS for PS
							titular characters(paragraph 5) in accordance with this Agreement
							shall be subject to the rules of such systems and in accordance
							with applicable international agreements and legislation of
							Russian Federation;
						</li>
						<li>
							Advice CLIENT on matters relating to the services rendered by
							SERVICE, and provide him with the necessary technical support;
						</li>
						<li>
							Store information about CLIENT's transactions in secret, except in
							cases of legitimate request of law enforcement and the judiciary
							or request of SAP and PS administration;
						</li>
						<li>
							If Customer doesn't get funds to its account after a operation of
							buy/sell is complete, in the cases provided by paragraph 3.3, 7.4,
							7.5 of this Agreement, then SERVICE makes a transfer CLIENT funds
							to its account for 48 hours after treatment with the appropriate
							customer complaint.
						</li>
					</ol>
				</li>
				<li>
					<h3>CLIENT shall</h3>
					<ol>
						<li>Follow this conditions entirety;</li>
						<li>
							Write accurate and reliable billing information - their own or
							third party in whose favor the operation is;
						</li>
						<li>Write a valid Email address and follow its workability;</li>
						<li>
							Before the transaction of buy/sell of CREDITS to ensure
							availability of SAP and paysystem servers;
						</li>
						<li>
							To inform the SERVICE of full or partial uncollected funds at the
							end of the operation, if any, in the cases provided by paragraph
							7.4, paragraph 7.5 of this Agreement, no later than 10 (ten)
							calendar days from the date of uncollected funds, otherwise the
							money accrue to the SERVICE;
						</li>
						<li>
							Comply with generally accepted standards of behavior and culture
							of communication when communicating with a support of SERVICE;
						</li>
						<li>
							Don't use the SERVICE to commit fraud or any kind of illegal
							financial transactions, launder illicit proceeds and other illegal
							activity;
						</li>
						<li>
							Do not perform any action that prevents normal work of the
							SERVICE.
						</li>
					</ol>
				</li>
				<li>
					<h3>SERVICE has the right</h3>
					<ol>
						<li>
							Modify the content of this agreement without notice to Customer;
						</li>
						<li>
							Modify the list of SAP (paragraph 4), to which the SERVICE is
							connected, without notice to Customer;
						</li>
						<li>
							Modify the list of paysystems (paragraph 5), to which is connected
							SERVICE, without notice to Customer;
						</li>
						<li>
							Temporarily suspend its operations for improvement or maintenance
							without timing of the suspension of work;
						</li>
						<li>
							Suspend the ongoing operations of buy/sell in the case of
							legitimate treatment of law enforcement, administration of SAP, PS
							or third parties with complaints of fraud to ascertain the
							circumstances;
						</li>
						<li>
							Set prices for its services and amount of CREDITS available for
							buy/sell at the moment, in its sole discretion;
						</li>
						<li>
							Block operation of buy/sell in the cases provided for paragraph
							7.4 or paragraph 7.5 of this Agreement;
						</li>
						<li>
							Require Customer confirm of his involvement in the operation by
							Email or WMID, specified for this operation, if CLIENT contacts
							SERVICE Support;
						</li>
						<li>
							Stop all negotiations with the CLIENT if he behaves rudely and
							asks questions not pertaining to the topic or does not provide the
							information needed to solve its problems;
						</li>
						<li>
							To refuse any client in the provision of services without giving a
							reason.
						</li>
					</ol>
				</li>
			</ol>
			<li>
				<h3>List of SAP, to which the SERVICE is connected</h3>
				<ol>
					<li>JetSwap.Located at www.jetswap.com</li>
					<li>HitHost.Located at www.hithost.ru</li>
					<li>IpWeb.Located at www.ipweb.ru</li>
					<li>Uniq-Ip.Located at www.uniq-ip.com</li>
				</ol>
			</li>
			<li>
				<h3>List of paysystems, to which the SERVICE is connected</h3>
				<ol>
					<li>Yandex.Money.Located at www.money.yandex.ru</li>
					<li>Payeer.Located at www.payeer.com</li>
				</ol>
			</li>
			<li>
				<h3>Services of SERVICE</h3>
				<ol>
					<li>
						SERVICE provides services for automatic buy/sell CREDITS, which are
						the internal medium of exchange SAP(paragraph 4), and representing
						customer value according to the rules of SAP;
					</li>
					<li>
						SERVICE doesn't check a legality of possession Customer CREDITS,
						means of payment and accounts, which specified in the details of
						transactions of buy/sell.
					</li>
				</ol>
			</li>
			<li>
				<h3>Regulation of buy/sell CREDITS operations</h3>
				<ol>
					<li>
						Buy/sell transactions considered to have begun when the service
						received from the Customer the full amount (in CREDITS or money), to
						pay for the operation;
					</li>
					<li>
						Buy/sell is complete when SERVICE transfered to CLIENT account
						(specified for this operation as the recipient's details) full owed
						amount (in CREDITS or money);
					</li>
					<li>
						Cancel started operation and return of funds paid are not possible,
						except as specified in paragraph 7.5 of this Agreement;
					</li>
					<li>
						If in the course of the operation CLIENT funds came in a different
						size than what was indicated in the form, SERVICE will block its
						operation, then, after CLIENT contacts SERVICE according to 3.2.5 of
						this Agreement, SERVICE will return received amount reduced by
						comission of SAP (paragraph 4) or PS (paragraph 5);
					</li>
					<li>
						If an operation can not be completed (details of the recipient
						blocked or non-existent), SERVICE, after CLIENT contact with SERVICE
						according to the 3.2.5 of this Agreement, return the funds received
						from the CLIENT on the details of the sender minus the commission to
						transfer funds in accordance with the terms of the Commission,
						acting in the CAP (paragraph 4) and PS (paragraph 5);
					</li>
					<li>
						Commission to transfer funds to the SERVICE during the operation
						paid by the CLIENT;
					</li>
					<li>
						Commission to transfer funds to the CLIENT during the operation paid
						by the SERVICE.
					</li>
				</ol>
			</li>
			<li>
				<h3>Responsibility</h3>
				<ol>
					<li>
						SERVICE is not responsible for and shall indemnify the CLIENT caused
						by incorrect use of the SERVICE or for any loss due to
						incorrect(wrong) specifying details of the CLIENT.
					</li>
					<li>
						SERVICE is not responsible for any errors or delays in payments
						SAP(item 4) and PS(paragraph 5).
					</li>
					<li>
						Responsibility for the use of the SERVICE to commit fraud of any
						kind, or for actions that prevent normal operation of the SERVICE,
						is an unscrupulous CLIENT.
					</li>
				</ol>
			</li>
			<li>
				<h3>Changing the current Agreement</h3>
				<ol>
					<li>
						This Agreement may at any time be amended or supplemented by the
						administration of the SERVICE.The new agreement comes into force at
						the time of placing it on the SERVICE website at
						www.wewec.com/terms.
					</li>
				</ol>
			</li>
			<li>
				<h3>Force majeure</h3>
				<ol>
					<li>
						Parties are not responsible for any failure or delay in performing
						its obligations under this Agreement, in the event of force majeure,
						including(without limitation) acts of God, war, act of fire(fire),
						floods, explosions, acts of government or regulatory authorities,
						terrorism, riot, civil commotion, hacker attacks, absence, failure
						or non-functioning of the electricity, Internet service providers,
						networks or other systems, networks and services.
					</li>
				</ol>
			</li>
			<li>
				<h3>Resolve disputes</h3>
				<ol>
					<li>
						All disputes arising between the parties in the course of execution
						of this Agreement shall be resolved on their own, in the process of
						negotiations.The Parties shall make every effort to settle the
						dispute.
					</li>
					<li>
						In all cases not provided for in this Agreement shall be governed by
						international agreements and legislation of Russian Federation.
					</li>
					<li>
						When parties are unable to resolve the dispute or disagreement
						through negotiation, then they may apply to the court for the
						protection of their rights and interests.
					</li>
				</ol>
			</li>
		</ol>
		<p
			css={css`
				text-align: right;
			`}
		>
			Revision of June 11, 2013
		</p>
	</Page>
)
