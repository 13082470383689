import { Account } from '../components/Data/Types'

export interface AccountsState {
	readonly loading: boolean
	readonly all: Account[]
}

export const GETTINGALLACCOUNTS = 'GettingAllAccounts'
export const GOTGALLACCOUNTS = 'GotAllAccounts'

export const gettingAllAccountsAction = () =>
	({ type: GETTINGALLACCOUNTS } as const)
export const gotAllAccountsAction = (data: Account[]) =>
	({ type: GOTGALLACCOUNTS, accounts: data } as const)

type AccountsActions =
	| ReturnType<typeof gettingAllAccountsAction>
	| ReturnType<typeof gotAllAccountsAction>

const initialRentsState: AccountsState = {
	loading: false,
	all: [],
}

export const accountsReducer = (
	state = initialRentsState,
	action: AccountsActions,
) => {
	switch (action.type) {
		case GETTINGALLACCOUNTS: {
			return { ...state, loading: true }
		}
		case GOTGALLACCOUNTS: {
			return { ...state, all: action.accounts, loading: false }
		}
		default:
			return state
	}
}
