import { City } from '../components/Data/Types'

export interface CitiesState {
	readonly loading: boolean
	readonly all: City[]
}

export const GETTINGALLCITIES = 'GettingAllCities'
export const GOTGALLCITIES = 'GotAllCities'

export const gettingAllCitiesAction = () =>
	({ type: GETTINGALLCITIES } as const)
export const gotAllCitiesAction = (data: City[]) =>
	({ type: GOTGALLCITIES, cities: data } as const)

type CitiesActions =
	| ReturnType<typeof gettingAllCitiesAction>
	| ReturnType<typeof gotAllCitiesAction>

const initialRentsState: CitiesState = {
	loading: false,
	all: [],
}

export const citiesReducer = (
	state = initialRentsState,
	action: CitiesActions,
) => {
	switch (action.type) {
		case GETTINGALLCITIES: {
			return { ...state, loading: true }
		}
		case GOTGALLCITIES: {
			return { ...state, all: action.cities, loading: false }
		}
		default:
			return state
	}
}
