/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import { TextBox, Validator } from 'devextreme-react'
import { CustomRule, RequiredRule } from 'devextreme-react/validator'
import { Captcha, captchaSettings } from 'reactjs-captcha'

export const getCaptchaId = () => {
	// get the id of a captcha instance that the user tried to solve
	return Captcha.captcha.getCaptchaId()
}

export const reloadCaptchaImage = () => {
	//in order to generate a new captcha challenge
	return Captcha.captcha.reloadImage()
}

interface Props {
	isValid: boolean
	a: (value: string) => void
	b: (value: boolean) => void
}

//https://captcha.com
//https://www.npmjs.com/package/reactjs-captcha
export const BotDetectCaptcha = ({ isValid, a, b }: Props) => {
	// set the captchaEndpoint property to point to
	// the captcha endpoint path on your app's backend
	captchaSettings.set({
		captchaEndpoint: 'botdetect.ashx',
	})

	return (
		<>
			<TextBox
				width="100"
				maxLength="5"
				showClearButton={true}
				onValueChanged={(e: any) => {
					a(e.value)
					//false: captcha validation failed; show the error message
					b(e.value ? true : false)
				}}
				placeholder="CAPTCHA"
				label="CAPTCHA"
				labelMode="floating"
			>
				<Validator>
					<RequiredRule message="Image code is required" />
					<CustomRule
						reevaluate={true}
						validationCallback={() => {
							return isValid
						}}
						message="CAPTCHA validation failed!"
					/>
				</Validator>
			</TextBox>
			<div
				css={css`
					margin-left: 4px;
				`}
			>
				<input type="hidden" id="userCaptchaInput" />
				<Captcha
					captchaStyleName="reactBasicCaptcha"
					ref={(captcha: any) => {
						Captcha.captcha = captcha
					}}
				/>
			</div>
		</>
	)
}
