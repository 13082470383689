import { RentAccountGame } from '../components/Data/Types'

export interface RentAccountGamesState {
	readonly loading: boolean
	readonly all: RentAccountGame[]
}

export const GETTINGALLRENTACCOUNTGAMES = 'GettingAllRentAccountGames'
export const GOTGALLRENTACCOUNTGAMES = 'GotAllRentAccountGames'

export const gettingAllRentAccountGamesAction = () =>
	({ type: GETTINGALLRENTACCOUNTGAMES } as const)
export const gotAllRentAccountGamesAction = (data: RentAccountGame[]) =>
	({ type: GOTGALLRENTACCOUNTGAMES, rentDevices: data } as const)

type RentAccountGamesActions =
	| ReturnType<typeof gettingAllRentAccountGamesAction>
	| ReturnType<typeof gotAllRentAccountGamesAction>

const initialRentsState: RentAccountGamesState = {
	loading: false,
	all: [],
}

export const rentAccountGamesReducer = (
	state = initialRentsState,
	action: RentAccountGamesActions,
) => {
	switch (action.type) {
		case GETTINGALLRENTACCOUNTGAMES: {
			return { ...state, loading: true }
		}
		case GOTGALLRENTACCOUNTGAMES: {
			return { ...state, all: action.rentDevices, loading: false }
		}
		default:
			return state
	}
}
