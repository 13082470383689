/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
	MenuItemLink,
	MenuItemLink2,
	Hr,
	MenuItem,
	MenuSubItem,
} from './Styles'
import React, { useState } from 'react'
import { ExternalLink } from 'react-external-link'
import { Email } from './Email'
import { Link } from 'react-router-dom'
import { SignIn } from './SignIn'
import { IdentityRoles } from '../AppSettings'
import { AuthorizedArea } from './AuthorizedArea'

interface Props {
	children: any
}

interface IMenuItem {
	id: number
	name: string
	url?: string
	role?: string
	items?: IMenuItem[]
}

export const Layout = ({ children }: Props) => {
	const [menu] = useState<IMenuItem[]>([
		{
			id: 1,
			name: 'Jetswap',
			items: [
				{ id: 11, name: 'Buy', url: '/buy' },
				{ id: 12, name: 'Sell', url: '/sell' },
				{ id: 13, name: 'FAQ', url: '/faq' },
				{ id: 14, name: 'Systems Of Active Promotion', url: '/sap' },
			],
		},
		{
			//todo не перерисовывает меню после авторизации, требует полную перезагрузку страницы
			id: 2,
			name: 'PlayStation',
			items: [
				{
					id: 21,
					name: 'Новая аренда',
					url: '/psrentcreate',
					role: IdentityRoles.PS_MANAGER,
				},
				{
					id: 22,
					name: 'Аренды',
					url: '/psrents',
					role: IdentityRoles.PS_MANAGER,
				},
				{
					id: 23,
					name: 'Игры',
					url: '/psgames',
					role: IdentityRoles.PS_MANAGER,
				},
				{
					id: 24,
					name: 'Клиенты',
					url: '/psclients',
					role: IdentityRoles.PS_MANAGER,
				},
				{
					id: 25,
					name: 'Аккаунты',
					url: '/psaccounts',
					role: IdentityRoles.PS_MANAGER,
				},
				{
					id: 26,
					name: 'Оборудование',
					url: '/psdevices',
					role: IdentityRoles.PS_MANAGER,
				},
			],
			role: IdentityRoles.PS_MANAGER,
		},
		{ id: 3, name: 'Contacts', url: '/#contacts' },
	])

	const expression =
		/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
	const regex = new RegExp(expression)

	//todo обработку ссылок # переделать на HashLink
	const expression2 = /^#/
	const regex2 = new RegExp(expression2)

	return (
		<div
			css={css`
				margin: 0;
				background: #fff url('/cell_flip.png') repeat-x left bottom;
				font-family: Arial, Helvetica, sans-serif;
				font-size: 11pt;
				color: #333;
				cursor: default;
			`}
		>
			<div
				css={css`
					background: url('/cell.png') repeat-x;
				`}
			>
				<div
					css={css`
						display: flex;
						flex-direction: column;
						align-items: center;
						max-width: 750px;
						margin: 0 auto;
					`}
				>
					<div
						css={css`
							display: flex;
							flex-direction: column;
							max-width: 750px;
							padding-top: 5px;
						`}
					>
						<div
							css={css`
								display: flex;
								justify-content: flex-end;
								align-self: flex-end;
								list-style: none;
								background: #fff;
								background: linear-gradient(
									to bottom,
									#fff 0%,
									#f3f3f3 50%,
									#ededed 51%,
									#fff 100%
								);
								filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#ffffff',GradientType=0);
								line-height: 0;
								border: 1px solid #ddd;
								border-radius: 5px;
								-moz-border-radius: 5px;
								-webkit-border-radius: 5px;
								box-shadow: 0 0 5px #ccc;
								-moz-box-shadow: 0 0 5px #ccc;
								-webkit-box-shadow: 0 0 5px #ccc;
								background-color: #fff;
								margin-right: 41px;
							`}
						>
							{/*
							<Language>
								<Link
									css={css`
										font-size: 11px;
										color: #00a8ec;
										line-height: 1;
										text-decoration: none;
									`}
									to="/en/Buy"
								>
									<img
										css={css`
											padding: 0 4px;
										`}
										src="/flag_en.png"
										alt="English language"
									/>
									<span
										css={css`
											padding: 0 8px 0 0;
										`}
									>
										English
									</span>
								</Link>
							</Language>
							<Language>
								<Link
									css={css`
										font-size: 11px;
										color: #00a8ec;
										line-height: 1;
										text-decoration: none;
									`}
									to="/Buy"
								>
									<img
										css={css`
											padding: 0 4px;
										`}
										src="/flag_ru.png"
										alt="Русский язык"
									/>
									<span
										css={css`
											padding: 0 8px 0 0;
										`}
									>
										Русский
									</span>
								</Link>
							</Language>
							*/}
						</div>
						<div
							css={css`
								font-family: Arial, Helvetica, sans-serif;
								font-size: 11pt;
								color: #333;
								cursor: default;
								border: none;
								outline: none;
								display: flex;
								justify-content: center;
								align-items: center;
							`}
						>
							<div
								css={css`
									width: 100px;
									height: 58px;
								`}
							>
								<a href="/">
									<img src="/we_logo.png" alt="wewec.com" />
								</a>
							</div>
							<div
								css={css`
									max-width: 700px;
									background: #ff1a00 url('/menu.png');
									border: 1px solid #960014;
									border-radius: 3px;
									box-shadow: 1px 1px 3px #aaa, 0 0 3px #fff inset;
								`}
							>
								<ul
									css={css`
										list-style: none;
										margin: 0 10px;
										margin-right: 60px;
									`}
								>
									{menu.map(r => (
										<AuthorizedArea role={r.role} key={r.id}>
											<li css={MenuItem} /*key={r.id}*/>
												{r.url && r.url.match(regex) ? (
													<ExternalLink css={MenuItemLink} href={r.url}>
														{r.name}
													</ExternalLink>
												) : (
													<Link css={MenuItemLink} to={r.url ? r.url : '#'}>
														{r.name}
													</Link>
												)}
												{!r.url && r.items ? (
													<ul css={MenuSubItem}>
														{r.items.map(r2 => (
															<li key={r2.id}>
																{r2.url && r2.url.match(regex) ? (
																	<ExternalLink
																		css={MenuItemLink2}
																		href={r2.url}
																	>
																		{r2.name}
																	</ExternalLink>
																) : (
																	<Link
																		css={MenuItemLink2}
																		to={r2.url ? r2.url : '#'}
																	>
																		{r2.name}
																	</Link>
																)}
															</li>
														))}
													</ul>
												) : (
													''
												)}
											</li>
										</AuthorizedArea>
									))}
									<SignIn />
								</ul>
							</div>
							<div
								css={css`
									color: #fff;
									font-size: 14px;
									padding: 4px 6px;
									margin: 0 4px;
									background: #333;
									border-radius: 50px;
								`}
								title="Minimal user age"
							>
								{process.env.REACT_APP_ENV === undefined ? 'DEV_' : ''}
								16+
							</div>
						</div>
					</div>
					<div
						css={css`
							display: flex;
							flex-direction: column;
							align-items: center;
							width: 100%;
						`}
					>
						{children}
					</div>
					<footer
						css={css`
							display: flex;
							flex-direction: column;
							flex-wrap: wrap;
						`}
					>
						<Hr />
						<h1
							id="contacts"
							css={css`
								width: 100%;
								margin: 0;
								color: #000;
								font-size: 24px;
								font-weight: normal;
							`}
						>
							Contacts
						</h1>
						<div
							css={css`
								display: flex;
								align-items: center;
								width: 100%;
							`}
						>
							<div
								css={css`
									padding: 10px;
									font-size: 13px;
								`}
							>
								<p>
									Support services (search fees, technical problems and your
									suggestions):
								</p>
								<Email to="support@onestatus.org" />
								<p>Postal robot (it is not respond to letters):</p>
								<Email to="noreply@wewec.com" />
							</div>
							<div
								css={css`
									padding: 10px;
									font-size: 13px;
								`}
							>
								<div
									css={css`
										display: flex;
										justify-content: center;
										width: 100%;
										font-size: 11px;
										position: relative;
										top: -15px;
										padding: 20px;
										background: #efefef;
										border: 1px dashed #ccc;
										color: #666;
										border-radius: 10px;
									`}
								>
									<h5
										css={css`
											margin: 0;
											font-size: 14px;
										`}
									>
										Be careful
									</h5>
									<p
										css={css`
											background: url('warning.png') no-repeat left center;
											margin: 5px 0 0 0;
											padding: 5px 0 5px 24px;
											font-size: 11px;
											font-style: italic;
											min-height: 40px;
										`}
									>
										Representatives WEWEC.COM receive correspondence and have
										corresponded only with those addresses. Representatives
										WEWEC.COM never offer to pay without the automatic interface
										of exchange!
									</p>
								</div>
							</div>
						</div>
						<Hr />
						<div
							css={css`
								width: 100%;
							`}
						>
							<div>
								<div
									css={css`
										margin: 0 10px 0 0;
										float: left;
									`}
								>
									<ExternalLink href="https://yoomoney.ru">
										<img
											src="/yandex_88x31_en.png"
											title="yooMoney"
											alt="yooMoney"
										/>
									</ExternalLink>
								</div>
								{/*todo вернуть после восстановления
								<div
									css={css`
										margin: 0 10px 0 0;
										float: left;
									`}
								>
									<ExternalLink href="https://qiwi.com">
										<img src="/qiwi_88x31.png" title="Qiwi" alt="Qiwi" />
									</ExternalLink>
								</div>
								<div
									css={css`
										margin: 0 10px 0 0;
										float: left;
									`}
								>
									<ExternalLink href="https://payeer.com/?partner=296">
										<img src="/payeer_88x31.png" title="Payeer" alt="Payeer" />
									</ExternalLink>
								</div>
								*/}
							</div>
							<div
								css={css`
									margin: 5px 0 0 0;
								`}
							>
								<p
									css={css`
										text-align: right;
										line-height: 1;
										font-family: Tahoma, Geneva, sans-serif;
										font-size: 10px;
										color: #555;
									`}
								>
									© 2009-2021 Credits Exchange of Systems Of Active Promotion
									WEWEC.COM
								</p>
							</div>
						</div>
					</footer>
				</div>
			</div>
		</div>
	)
}
