import { Page } from '../Page'
import { Uri } from '../../AppSettings'
import DataGrid, {
	Column,
	Editing,
	MasterDetail,
	Paging,
	Scrolling,
	SearchPanel,
} from 'devextreme-react/data-grid'
import { useAuth } from 'oidc-react'
import CustomStore from 'devextreme/data/custom_store'
import 'whatwg-fetch'
import { AccountDetails } from '../../components/AccountDetails'
import { Account } from '../../components/Data/Types'
import {
	RequestGet,
	RequestPatch,
	RequestPost,
} from '../../components/Data/Functions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { AppState } from '../../store/Store'
import {
	gettingAllAccountsAction,
	gotAllAccountsAction,
} from '../../store/AccountsReducer'

export const AccountsPage = () => {
	const authState = useAuth()
	const getUser = async () =>
		await authState.userManager.getUser().then(user => user)
	const dispatch = useDispatch()
	const accounts = useSelector((state: AppState) => state.accounts.all)

	useEffect(() => {
		const getData = async () => {
			const user = await getUser()
			if (user) {
				dispatch(gettingAllAccountsAction())
				const data = await RequestGet<Account[]>(
					Uri.Accounts,
					response => response.data,
					user.access_token,
				)
				dispatch(gotAllAccountsAction(data))
			}
		}

		getData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onInitNewRow = (e: any) => {
		//галочка по умолчанию активна
		e.data.forRent = true
	}

	//тут хорошее описание работы с редактором
	//https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/React/Light/
	const accountsStore = new CustomStore({
		key: 'id',
		loadMode: 'raw',
		load: () => accounts,
		insert: async value => {
			const user = await getUser()

			if (user) {
				return RequestPost<Account>(
					Uri.Accounts,
					value,
					undefined,
					undefined,
					undefined,
					user.access_token,
				)
			}
		},
		update: async (key, value) => {
			const user = await getUser()

			if (user) {
				return RequestPatch<Account>(
					`${Uri.Accounts}/${key}`,
					response => response.data,
					value,
					user.access_token,
				)
			}
		},
	})

	return (
		<Page title="Аккаунты с играми PlayStation">
			<DataGrid
				dataSource={accountsStore}
				allowColumnReordering={true}
				allowColumnResizing={true}
				columnResizingMode="widget"
				hoverStateEnabled={true}
				onInitNewRow={onInitNewRow}
			>
				<Scrolling mode="standard" />
				<Paging enabled={false} />
				<SearchPanel visible={true} />
				<Editing
					mode="form"
					allowUpdating={true}
					allowAdding={true}
					//allowDeleting={true}
					useIcons={true}
					newRowPosition="first"
					refreshMode="reshape"
				/>
				<Column
					dataField="id"
					caption="#"
					width={50}
					allowEditing={false}
					defaultSortIndex={0}
					defaultSortOrder="desc"
				/>
				<Column dataField="name" dataType="string" caption="Наименование" />
				<Column dataField="forRent" dataType="boolean" caption="Для аренды" />
				<Column
					dataField="password"
					dataType="string"
					caption="Пароль"
					visible={false}
				/>
				<MasterDetail enabled={true} component={AccountDetails} />
			</DataGrid>
		</Page>
	)
}
