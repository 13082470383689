/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { SerializedStyles } from '@emotion/react'
import {
	Button,
	LoadIndicator,
	Popup,
	TextBox,
	ValidationSummary,
	Validator,
} from 'devextreme-react'
import { ToolbarItem } from 'devextreme-react/popup'
import { RequestError } from '../components/Data/Types'
import { useParams } from 'react-router-dom'
import { Uri } from '../AppSettings'
import { Controller, useForm } from 'react-hook-form'
import { CompareRule, RequiredRule } from 'devextreme-react/validator'
import { Page } from './Page'
import { SignInLink } from '../components/SignInLink'
import { RequestPost } from '../components/Data/Functions'

interface FormData {
	password: string
	passwordConfirm: string
}

export const PasswordResetByHashPage = () => {
	const { hash } = useParams()

	const { control, setValue, getValues, watch, handleSubmit } =
		useForm<FormData>()

	const [popupVisible, setPopupVisible] = useState<boolean>(false)
	const [popupContent, setPopupContent] = useState<
		React.ReactNode | string | null
	>()

	const hidePopup = () => {
		setPopupVisible(false)
	}
	const [passwordChanged, setPasswordChanged] = useState(false)
	const [loading, setLoading] = useState(false)

	const submitForm = (form: FormData) => {
		let value = {
			hash: hash,
			password: form.password,
		}

		setLoading(true)

		RequestPost<any>(
			Uri.PasswordResetByHash,
			value,
			response => {
				setPasswordChanged(true)
			},
			ex => {
				if (ex.response) {
					// client received an error response (5xx, 4xx)
					if (ex.response.status === 400) {
						const errors: RequestError[] = ex.response.data.errors

						if (errors) {
							setPopupContent(
								<ul>
									{errors.map((value, index) => (
										<li key={index}>{value.message}</li>
									))}
								</ul>,
							)
						}
					} else {
						setPopupContent('Unprocessed error. Please, contact us.')
					}

					setPopupVisible(true)
				} else if (ex.request) {
					// client never received a response, or request never left
				} else {
					// anything else
					//throw new Error(ex)
				}

				//todo не работает и вроде не нужен после ререндера
				//reloadCaptchaImage()
			},
			() => {
				setLoading(false)
			},
		)
	}

	return loading ? (
		<LoadIndicator id="large-indicator" height={60} width={60} />
	) : (
		<Page title="Password reset">
			<Popup
				visible={popupVisible}
				onHiding={hidePopup}
				dragEnabled={false}
				closeOnOutsideClick={true}
				showCloseButton={true}
				showTitle={true}
				title="Information"
				container=".dx-viewport"
				width={300}
				height={280}
			>
				<ToolbarItem
					widget="dxButton"
					toolbar="bottom"
					location="after"
					options={{
						text: 'OK',
						onClick: hidePopup,
					}}
				/>
				{popupContent}
			</Popup>
			{passwordChanged ? (
				<div>
					Password successfully changed. Try to <SignInLink>Sign In</SignInLink>
				</div>
			) : (
				<>
					<p>Enter and confirm new password.</p>
					<form
						css={css`
							width: 100%;
							display: flex;
							flex-direction: column;
						`}
						onSubmit={handleSubmit(submitForm)}
					>
						<div
							className="dx-fieldset"
							css={css`
								margin: 0;
								width: 100%;
							`}
						>
							<div className="dx-field ">
								<div className="dx-field-value">
									<Controller
										control={control}
										name="password"
										render={({ field: { onChange, value } }) => (
											<TextBox
												width="200"
												mode="password"
												defaultValue={value}
												maxLength="50"
												valueChangeEvent="keyup"
												onValueChanged={e => onChange(e.value)}
											>
												<Validator>
													<RequiredRule message="Password is required" />
												</Validator>
											</TextBox>
										)}
									/>
								</div>
								<div
									className="dx-field-label"
									css={css`
										text-align: right;
									`}
								>
									Password
								</div>
							</div>
							<div className="dx-field ">
								<div className="dx-field-value">
									<Controller
										control={control}
										name="passwordConfirm"
										render={({ field: { onChange, value } }) => (
											<TextBox
												width="200"
												mode="password"
												defaultValue={value}
												maxLength="50"
												valueChangeEvent="keyup"
												onValueChanged={e => onChange(e.value)}
											>
												<Validator>
													<RequiredRule message="Confirm Password is required" />
													<CompareRule
														message="Password and Confirm Password do not match"
														comparisonTarget={() => {
															return getValues('password')
														}}
													/>
												</Validator>
											</TextBox>
										)}
									/>
								</div>
								<div
									className="dx-field-label"
									css={css`
										text-align: right;
									`}
								>
									Confirm Password
								</div>
							</div>
							<ValidationSummary id="summary" />
						</div>
						<p></p>
						<Button
							text="Set New Password"
							type="success"
							stylingMode="contained"
							useSubmitBehavior={true}
						/>
					</form>
				</>
			)}
		</Page>
	)
}
