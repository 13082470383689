import { Game } from '../components/Data/Types'

export interface GamesState {
	readonly loading: boolean
	readonly all: Game[]
	readonly one: Game | null
}

export const GETTINGALLGAMES = 'GettingAllGames'
export const GOTGALLGAMES = 'GotAllGames'
export const GETTINGGAME = 'GettingGame'
export const GOTGGAME = 'GotGame'

export const gettingAllGamesAction = () => ({ type: GETTINGALLGAMES } as const)
export const gotAllGamesAction = (data: Game[]) =>
	({ type: GOTGALLGAMES, games: data } as const)
export const gettingGameAction = () => ({ type: GETTINGGAME } as const)
export const gotGameAction = (data: Game) =>
	({ type: GOTGGAME, game: data } as const)

type GamesActions =
	| ReturnType<typeof gettingAllGamesAction>
	| ReturnType<typeof gotAllGamesAction>
	| ReturnType<typeof gettingGameAction>
	| ReturnType<typeof gotGameAction>

const initialGamesState: GamesState = {
	loading: false,
	all: [],
	one: null,
}

export const gamesReducer = (
	state = initialGamesState,
	action: GamesActions,
) => {
	switch (action.type) {
		case GETTINGALLGAMES: {
			return { ...state, loading: true }
		}
		case GOTGALLGAMES: {
			return { ...state, all: action.games, loading: false }
		}
		case GETTINGGAME: {
			return { ...state, loading: true }
		}
		case GOTGGAME: {
			return { ...state, one: action.game, loading: false }
		}
		default:
			return state
	}
}
