/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

interface Props {
	title?: string
	children: React.ReactNode
}

export const Page = ({ title, children }: Props) => (
	<div
		css={css`
			width: 100%;
			display: flex;
			flex-direction: column;
		`}
	>
		{title && (
			<h1
				css={css`
					color: #000;
					font-size: 24px;
					font-weight: normal;
				`}
			>
				{title}
			</h1>
		)}
		{children}
	</div>
)
