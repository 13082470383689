import React, { FC } from 'react'
import {
	AuthProviderProps,
	User,
	AuthProviderSignOutProps,
	AuthProvider as OidcAuthProvider,
} from 'oidc-react'
import { useNavigate } from 'react-router'
import { Uri, oidcServer } from '../AppSettings'

export const AuthProvider: FC = ({ children }) => {
	const navigate = useNavigate()

	//https://github.com/bjerkio/oidc-react/blob/main/docs/interfaces/authcontextinterface.authproviderprops.md
	const customOidcConfig: AuthProviderProps = {
		clientId: 'react',
		authority: oidcServer,
		redirectUri: `${oidcServer}/signin/callback`,
		postLogoutRedirectUri: oidcServer,
		responseType: 'code',
		scope: 'openid profile apiScopeClaims',
		autoSignIn: false, //todo блокируем автоматическую переадресацию на страницу с авторизацией. Возможно нужно вернуть true

		// если true, клиент попытается обновить access_token перед его истечением, по умолчанию false
		automaticSilentRenew: true,
		// эта страница используется для "фонового" обновления токена пользователя через iframe
		silentRedirectUri: `${oidcServer}/signin/silentcallback`,

		onBeforeSignIn: () => {
			//console.log(1)
			/**
			 * This method gets executed before signin redirecting begins
			 * it can be used to store Uri
			 */
		},
		onSignIn: async (user: User | null) => {
			//console.log(2)
			/*
			if(user) {
				alert('You just signed in, congratz! Check out the console!')
				console.log(user)
			}
			*/
			if (user) {
				//todo лучше редиректить на последнюю страницу, на которой был пользователь
				navigate(Uri.RedirectAfterSignIn)
				window.location.hash = ''
			} else {
				//todo нужна обработка
				console.log('User not logged in')
			}
		},
		onSignOut: async (options?: AuthProviderSignOutProps) => {
			//console.log(3)
			/*
			if(options)
				console.log(options.signoutRedirect)
			*/
			//todo лучше редиректить на последнюю страницу, на которой был пользователь
			//navigate(Uri.RedirectAfterSignOut)
			window.location.hash = ''
		},
	}

	return <OidcAuthProvider {...customOidcConfig}>{children}</OidcAuthProvider>
}

/*Провайдер из книги React + Net 5
interface AuthUser {
	name?: string
	email?: string
}

interface IAuthContext {
	isAuthenticated: boolean
	user?: AuthUser
	signIn: () => void
	signOut: () => void
	loading: boolean
}

export const AuthContext = React.createContext<IAuthContext>({
	isAuthenticated: false,
	signIn: () => {},
	signOut: () => {},
	loading: true,
})

//todo заглушки
type AuthClient = {
	loginWithRedirect: () => void
	logout: () => void
	handleRedirectCallback: () => void
	isAuthenticated: () => Promise<boolean>
	getUser: () => AuthUser
}

export const useAuth = () => React.useContext(AuthContext)

export const AuthProvider: React.FC = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
	const [user, setUser] = useState<AuthUser | undefined>(undefined)
	const [authClient, setAuthClient] = useState<AuthClient>()
	const [loading, setLoading] = useState<boolean>(true)

	const getAuthClientFromState = () => {
		if (authClient === undefined) {
			throw new Error('Auth client not set')
		}

		return authClient
	}

	React.useEffect(() => {
		const initAuth = async () => {
			setLoading(true)

			//todo заглушка
			//const authClientFromHook = await createAuthClient(authSettings)
			const authClientFromHook: AuthClient = {
				loginWithRedirect: () => {},
				logout: () => {},
				handleRedirectCallback: () => {},
				isAuthenticated: async (): Promise<boolean> => {
					//todo управляем состоянием авторизации
					//return false
					return true
				},
				getUser: () => {
					const user: AuthUser = {
						email: 'test@test.ru',
						name: 'Test User',
					}

					return user
				},
			}

			setAuthClient(authClientFromHook)

			if (
				window.location.pathname === '/signin-callback' &&
				window.location.search.indexOf('code=') > -1
			) {
				await authClientFromHook.handleRedirectCallback()
				window.location.replace(window.location.origin)
			}

			const isAuthenticatedFromHook = await authClientFromHook.isAuthenticated()

			if (isAuthenticatedFromHook) {
				const user = await authClientFromHook.getUser()
				setUser(user)
			}
			setIsAuthenticated(isAuthenticatedFromHook)
			setLoading(false)
		}

		initAuth()
	}, [])

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				user,
				signIn: () => getAuthClientFromState().loginWithRedirect(),
				signOut: () =>
					//todo заглушка
                    //getAuthClientFromState().logout({
                    //    client_id: authSettings.client_id,
                    //    returnTo: window.location.origin + '/signout-callback',
                    //}),
					getAuthClientFromState().logout(),
				loading,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

//todo метод для возврата текущего токена
//export const getAccessToken = async () => {
//	const authFromHook = await createAuthClient(authSettings)
//	const accessToken = await authFromHook.getTokenSilently()
//
//	return accessToken
//}
*/
